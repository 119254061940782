.selling-guides-container {
  min-height: 500px;

  width: 85%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
}
.previewImage-selling {
  max-width: 100%;
}
.upload-images-box .labelContainer {
  cursor: pointer;
}
.selling-sub-form-con .error-date-sec-dateof-birth-selling {
  border-color: red;
}
.selling-sub-form-con .hasError {
  border: 1px solid red;
}
.selling-header-con {
  color: var(--text-black, #263238);
  text-align: center;
  font-size: 46px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  margin-bottom: 30px;
}
.selling-sub-text {
  color: var(--text-black, #263238);
  text-align: center;
  font-size: 21px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 70px;
}
.s-how-it-work-text {
  color: #000;
  font-size: 28px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
}
.s-how-it-work-con {
  margin-top: 32px;
  margin-bottom: 80px;
  display: flex;
  gap: 30px;
}
.s-how-it-work-con-box {
  text-align: center;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 24px 12px;
  width: 32%;
}
.s-how-it-work-con-box .s-how-box-center {
  margin-top: 20px;
  margin-bottom: 14px;
}
.s-how-box-center {
  color: var(--text-black, #263238);
  text-align: center;
  font-size: 18px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}
.s-how-box-bottom {
  color: #7e7e7e;
  text-align: center;
  font-size: 16px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.selling-sub-form h4 {
  color: var(--text-black, #263238);
  /* text-align: center; */
  font-size: 32px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
}
.selling-basic-information-hea {
  color: var(--text-black, #263238);
  /* text-align: center; */
  font-size: 22px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.selling-text-box label {
  color: #111;
  font-size: 14px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
  margin-top: 26px;
  margin-bottom: 8px;
}
.selling-text-box textarea,
.selling-text-box select,
.selling-text-box input {
  width: 100%;
}
.selling-text-box textarea {
  /* color: rgba(17, 17, 17, 0.3); */
  color: #111;
  font-size: 14px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 16px;
}
.selling-sub-form {
  width: 60%;
  margin: auto;
}
.selling-text-box input {
  height: 45px;
  padding: 11px 16px;
}
.selling-sub-form-con {
  border-radius: 8px;
  margin-top: 30px;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(32, 32, 32, 0.08);

  padding: 48px;
}

.selling-bubmit-box {
  margin-top: 26px;
}
.selling-bubmit-box button {
  border-radius: 4px;
  border: 1px solid #111;
  background: #111;
  color: #fff;
  font-size: 16px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  height: 50px;
}
.upload-images-box .dualImages {
  display: flex;
  gap: 4%;
  justify-content: space-between;
}
.upload-images-box .dualImages .imageBlock {
  border-radius: 4px;
  border: 1px dashed #d3d3d3;
  background: #f6f6f6;
  width: 45%;
  text-align: center;
  height: 220px;
  padding-top: 30px;
}
.selling-contact-info {
  margin-top: 30px;
}
.upload-images-box .blackText {
  color: #ff4848;
  font-size: 12px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  line-height: 21.6px;
}
.upload-images-box .addImageText {
  color: #000;
  font-size: 14px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 400;
  line-height: 21.6px;
}
@media (max-width: 600px) {
  .selling-sub-form {
    width: 90%;
  }
  .selling-sub-form-con {
    padding: 25px;
  }
  .s-how-it-work-con {
    display: block;
  }
  .s-how-it-work-con-box {
    width: 100%;
    margin-bottom: 20px;
  }
  .selling-header-con {
    font-size: 24px;
    line-height: 35px;
  }
}
