.shipping-box-header {
  margin-top: 50px;
  margin-bottom: 150px;
}
.shipping-box-header p a {
  text-decoration: underline;
  /* text-decoration: underline !important;
  color: #000 !important; */
}
.shipping-box-header p {
  margin-bottom: 30px;
  color: #000;
  font-size: 18px;
  font-family: Noto Serif;
  line-height: 40px;
}
