.bids_page_main_heading {
  padding: 40px 15px;
}
.bids_page_main_heading h2 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 500;
  font-family: "Noto Serif", serif;
  color: #333333;
}

.more_collection_colum .custom_account_list_sidebar .list-group-item {
  padding: 25px;
  font-size: 18px;
  font-family: "Noto Serif", serif;
  color: rgba(0, 0, 0, 0.5);
  line-height: 24px;
  background: transparent;
  border-color: #00000020;
}

.custom_account_second_list_sidebar {
  margin-top: 40px;
}
.logout_btn {
  cursor: pointer;
}

@media (max-width: 991px) {
  .more_collection_colum .list-group {
    flex-direction: row;
    margin-top: 0;
  }
  .more_collection_colum .custom_account_list_sidebar .list-group-item {
    padding: 15px 20px;
    width: auto;
    border-top: 1px solid #00000020;
    border-left: 0;
    font-size: 16px;
  }
  .more_collection_colum
    .custom_account_list_sidebar
    .list-group-item:first-child {
    border-left: 1px solid #00000020;
    border-radius: 0;
  }
  .more_collection_colum
    .custom_account_list_sidebar
    .list-group-item:last-child {
    border-radius: 0;
  }
  .more_collection_colum {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .more_collection_colum .custom_account_list_sidebar .list-group-item {
    white-space: nowrap;
    border: none !important;
    margin: 0px;
    padding: 16px;
    font-weight: 500;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
  }
  .more_collection_colum {
    overflow-x: auto;
    margin-bottom: 0;
  }
  .bids_page_main_heading {
    display: none;
  }
}
