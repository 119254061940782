.followed_lots_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="followedlots"] {
  color: #000;
  font-weight: 600;
}

.followed_lots_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="followedlots"]:hover {
  background: #fff;
}

.followed_lots_page_content.purchases_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="purchases"] {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  background: transparent;
}

.followed_lots_page_content.purchases_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="purchases"]:hover {
  background: #fff;
}

.purchases_no_activity .noResults {
  padding: 34px 0px 0px 25px;
  font-size: 20px;
}

@media (max-width: 991px) {
  .followed_lots_page_content .purchases_no_activity {
    margin: 0 -24px;
  }
}

@media (max-width: 767px) {
  .followed_lots_page_content .purchases_no_activity {
    margin: 0 -16px;
  }
}

@media (max-width: 575px) {
  .followed_lots_page_content .purchases_no_activity .card-body {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
