.purchases_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="purchases"] {
  color: #000;
  font-weight: 600;
}

.purchases_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="purchases"]:hover {
  background: #fff;
}

.purchases_no_activity {
  margin: 0 -24px;
}

.purchases_page_content .purchases_no_activity .card-header button {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #111111;
  text-decoration: none;
  font-family: "Noto Serif", serif;
  padding-right: 30px;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  width: 100%;
}

.purchases_page_content .purchases_no_activity .card-header button img {
  transition: all 0.5s ease-in-out;
}

.purchases_page_content .purchases_no_activity .card {
  border-radius: 10px;
  border-left: 0;
  border-right: 0;
}

.purchases_page_content .purchases_no_activity .card-header button.show img {
  transform: rotate(180deg);
}

@media (max-width: 991px) {
  .purchases_no_activity {
    margin: 0;
  }
}

.purchases_page_right_content {
}
.LineStyle {
  width: 908px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-left: -25px;
}
.header_text_page {
  padding-left: 20px;
}
.PurchaseDiv {
  width: 100%;
  min-height: 231px;
  margin: 32px 0px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  /* opacity: 0%; */
  text-align: inside;
}
.headingtext {
  display: flex;
  border-bottom: 1px solid #c4c4c4;
  padding-top: 10px;
  padding-left: 25px;
}
.OrderIdText {
  width: 25%;
  /* height: 40px; */
  font-family: Noto serif;
  font-style: normal;
  font-size: 16px;
  line-height: 40px;
  line-height: 184%;
  text-align: left;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.7);
  margin: 0px !important;
}
.purchase_right_section {
  padding: 24px 0px !important;
}
.noRecordsData {
  margin: 40px;
}
.DateText {
  width: 20%;
  height: 40px;
  font-family: Noto serif;
  font-style: normal;
  font-size: 16px;
  line-height: 40px;
  line-height: 184%;
  /* text-align: right; */
  vertical-align: top;
  color: rgba(0, 0, 0, 0.7);
  margin: 0px !important;
  /* margin-left: 64px !important; */
}

.PaymentStatus {
  width: 50%;
  height: 40px;
  font-family: Noto serif;
  font-style: normal;
  font-size: 16px;
  line-height: 40px;
  line-height: 184%;
  text-align: right;
  vertical-align: top;
  margin: 0px !important;
  /* padding-left: 10px; */
  float: right;
}

.StatusText {
  color: rgba(0, 0, 0, 0.7);
}

.ImageStatusDiv {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  gap: 60px;
}
.image-status-div-left {
  display: flex;
}
.ImageCardDiv {
  width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 4px;
  color: #c4c4c4;
  fill: solid #c4c4c4;
  /* background-color:  #C4C4C4 !important; */
  border: none !important;
  margin-left: 25px;
}

.ImageCard {
  width: 100px;
  height: 100px;
}

.card {
  background-color: none !important;
}
.DetailsOfImages {
  display: inline;
  padding-left: 10px;
}

.PurchaseNameText {
  /* width: 345px; */
  /* height: 26px; */
  font-family: Noto serif;
  font-weight: 600;
  font-style: semi bold;
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  vertical-align: top;
  color: #111111;
}

.LotSoldUsdText {
  /* width: 151px; */
  height: 26px;
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  /* line-height: 86px; */
  text-align: left;
  vertical-align: top;
}

.LotSoldText {
  color: #c4c4c4;
}

.PaymentStatusDiv {
  display: grid;
  width: 190px;
  min-width: 190px;
  /* padding-left: 230px; */
}
.MakePaymentBtn {
  /* width: 176px; */
  height: 52px;
  border-radius: 4px;
  padding: 6px 22px 6px 22px;
  border: 1px solid #000000 !important;
  font-family: "Poppins";
  font-style: normal;
  font-size: 16px;
  line-height: 40px;
  line-height: 167%;
  text-align: center;
  vertical-align: top;
  color: #ffffff;
  background-color: #000000 !important;
}

.DownloadInvoiceBtn {
  width: 176px;
  height: 52px;
  border-radius: 4px;
  padding: 6px 16px 6px 16px;
  border: 1px solid #000000 !important;
  font-family: "Poppins";
  font-style: normal;
  font-size: 16px;
  line-height: 40px;
  line-height: 167%;
  text-align: left;
  vertical-align: top;
  color: #000000 !important;
  background-color: #ffffff !important;
  margin-top: 25px;
}

.InvoiceNameText {
  width: 531px;
  height: 26px;
  font-family: Noto serif;
  font-weight: 600;
  font-style: semi bold;
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  vertical-align: top;
  color: #111111;
}

.InvoiceStatusDiv {
  padding-left: 40px;
  margin-top: -36px;
}

.PaymentStatusDiv .btn {
  box-shadow: unset !important;
}
.lot-sold-sec-main-sec {
}

@media (max-width: 767px) {
  .purchases_page_content .purchases_no_activity .card-header button {
    font-size: 16px;
    line-height: 22px;
  }
  .purchases_page_content .responsive_bids_tab .nav-tabs {
    padding-bottom: 16px;
  }
  .headingtext {
    height: 78px;
  }
  .ImageStatusDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    grid-gap: 10px;
  }
  .PurchaseDiv {
    position: relative;
  }
  .PaymentStatusDiv {
    padding: 0;
    float: right;
    display: flex;
    justify-content: end;
    position: relative;
    /* margin-top: -40px; */
    margin-bottom: 20px;
    margin-right: 10px;
    width: 100%;
  }
  .MakePaymentBtn {
    width: 130px;
    height: 37px;
    color: #ffffff;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
    padding: 2px 10px;
    text-align: center !important;
  }
  .PurchaseDiv .headingtext-header {
    width: 100%;
    display: block;
    height: 110px;
  }
  .PurchaseDiv .headingtext-header > p {
    display: block;
    width: 100%;
    line-height: 16px;
    height: 33px;
  }
  .OrderIdText {
    line-height: 22px;
  }
  .headingtext-header .PaymentStatus {
    text-align: left;
  }
}
@media (max-width: 425px) {
  .PaymentStatusDiv {
    /* padding-left: 180px; */
  }
}
@media (min-width: 1368px) {
  .PaymentStatusDiv {
    /* padding-left: 320px; */
  }
}
