.articlePreviewHeadingRow {
  margin: 0;
  display: flex;
  align-items: center;
  margin-bottom: 32px !important;
}

.createArticleContainer > .createArticle {
  width: 860px !important;
  margin: auto;
}
.createArticleHeaderContainer {
  height: 300px;
  width: 100%;
  padding: 40px;
  background: #f5f5f5;
}
.row-image-article_new_extra img {
  max-height: 500px;
}
.btnsRowPreview {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  justify-content: space-between;
}
.Close_Preview {
  width: 138px;
  height: 50px;
  border: 1px solid #000000;
  background-color: #000000 !important;
  color: #fff;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.Close_Preview:hover {
  color: #fff !important;
  border: 1px solid #000000 !important;
}
.editArticle_Preview {
  margin-left: 800px;
  width: 124px;
  height: 50px;
  border: 1px solid #000000;
  background-color: #fff !important;
  color: #000000;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.editArticle_Preview:hover {
  border: 1px solid #000000;
  background-color: #fff !important;
  color: #000000;
}
.closeArticle_Preview {
  width: 178px;
  height: 50px;
  border: 1px solid #000000;
  background-color: #fff !important;
  color: #000000;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.closeArticle_Preview:hover {
  border: 1px solid #000000;
  background-color: #fff !important;
  color: #000000;
}

.contentMgmtHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  margin-left: 5px;
}
.textHeaderPreview {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
}
.previewArticleBody {
  width: 80%;
  margin: auto;
  padding: 20px 0px !important;
  font-family: Noto Serif;
  font-style: normal;
}
.previewArticle {
  box-shadow: none;
}
.headerPreviewContent {
  margin-top: 20px !important;
  height: auto;
  color: #000;
  font-size: 18px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.headerPreviewContent .public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: unset !important;
}
.headerContentViewArticle div,
.headerContentViewArticle img {
  max-width: 100% !important;
}
.PreviewTitle {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
}
.PreviewAuthor {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}
.PreviewAdescCon {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
.PreviewdisbaledText {
  font-size: 16px;
  line-height: 26px;
  color: #333333;
  opacity: 0.5;
}
.previewArticle img {
  max-width: 100%;
}
.the-end-sec {
  margin-top: 40px;
}
.the-end-sec-content {
  text-align: center;
}

.the-end-sec-content .dash-line {
  width: 126px;
  height: 1px;
  display: inline-block;
  background: #000;
  margin-top: -9px;
  top: -6px;
  position: relative;
}
.the-end-sec-content .dash-text {
  color: #000;
  font-size: 23px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  margin: 0 20px;
  line-height: 40px;
}
.row-image-article .v-img {
  border-top: 20px solid #000;
  border-bottom: 20px solid #000;
  border-left: 30px solid #000;
  border-right: 30px solid #000;
}
.row-image-article .h-img {
  border: 20px solid #000;
}
.imageRow {
  margin-left: 0px;
  margin-right: 15px;
}

.bannerCategoryText {
  font-weight: 700;
  text-align: left;
  vertical-align: top;
  color: #ffffff;
  font-size: 24px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.CategoryTextLine {
  width: 0px;
  color: #fdd490;
  transform: rotate(20deg);
  height: 19px;
  color: #fdd490;
  border: 2px solid #fdd490;
  background-color: #fdd490;
  margin-right: 5px;
}

.bannerTitleText {
  font-family: Noto serif;
  font-style: Bold;
  font-weight: 600;
  font-size: 60px;
  line-height: 82px;
  text-align: left;
  vertical-align: top;
  /* text-align: center; */
  color: #ffffff;
  color: #fff;
  text-align: center;
  font-size: 72px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bannerSubText {
  font-family: Noto serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  vertical-align: top;
  color: #ffffff;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.MoreRowText {
  font-family: Noto serif;
  font-style: "Semibold";
  font-size: 30px;
  line-height: 41px;
  text-align: left;
  vertical-align: top;
  color: #111111;
  font-weight: 600;
}

.MoreTextLine {
  width: 0px;
  color: #fdd490;
  transform: rotate(20deg);
  height: 30px;
  fill: #fdd490;
  border: 2px solid #fdd490;
  margin-right: 15px;
  background-color: #fdd490;
}

.ImagesRows {
  float: left;
  margin-left: -34px;
  /* margin-right:0px !important ;
      margin-left: 0px !important; */
}

.ImagesCols {
  margin-bottom: 40px;
  /* padding: 0px !important; */
}

.ImageCards {
  border: 0px !important;
  padding: 0px;
}

.StoriesText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  vertical-align: top;
  color: #333333;
  margin-top: 5px;
  margin-bottom: 5px;
}

.TheCollectionText {
  font-family: Noto serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
  vertical-align: top;
  color: #111111;
  padding: 0px !important;
  color: #111;
  font-size: 16px;
  font-family: Noto Serif;
  font-weight: 700;
  line-height: 26px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.DateTexts {
  font-family: "Poppins";
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  vertical-align: top;
  color: rgba(41, 41, 41, 0.5);
}

.StoriesLine {
  width: 0px;
  color: #fdd490;
  transform: rotate(20deg);
  height: 20px;
  fill: #fdd490 !important;
  border: 2px solid #fdd490;
  margin-right: 10px;
  background-color: #fdd490;
}
.imageSectionView,
.manageSiteManagerView {
  height: 600px;
}
/* .topbannerSection {
  padding: 45px 80px;
  background: #000;
} */
.imageSectionView img {
  max-height: -webkit-fill-available;
  object-fit: fill;
}
.shadow-text-row {
  color: #333;
  font-size: 14px;
  font-family: Noto Serif;
  line-height: 16.8px;
  opacity: 0.5;
  margin-bottom: 30px;
  margin-top: 13px;
  text-align: center;
}
.plain-text-row {
  color: #000;
  font-size: 18px;
  font-family: Noto Serif;
  line-height: 40px;
  margin-top: 20px;
}
.more-from-word-sec {
  margin-top: 50px;
}
.bold-text-row {
  color: #000;
  font-size: 24px;
  font-family: Noto Serif;
  font-weight: 700;
  line-height: 44px;
  margin-top: 20px;
}
.row-image-article {
  margin-top: 30px;
  justify-content: center;
}
.BottomSectionView {
  margin-top: -355px;
  color: #fff;
}
.BottomSectionView1 {
  margin-top: 155px;
}
.share-icon-header-sec {
  margin-top: -570px;
  color: #fff;
  text-align: right;
}
.share-icon-header-sec button {
  border-radius: 5px;
  padding: 11px;
  padding: 11px;
  border: none;
  margin-right: 20px;
  cursor: pointer;
}
.manageSiteManagerView1 {
  height: 600px;
  background: #000;
}
.manageSiteManagerView2 {
  width: 90%;
  margin: auto;
  margin-top: -550px;
}
.bannerCategoryText,
.bannerTitleText {
  /* margin-left: 15%; */
  text-align: center;
}
.PreviewArticleContainer .card {
  border: none;
}
.ImageMore {
  /* min-height: 300px;
  max-height: 300px; */
  object-fit: contain;
}
/* .ImagesRows .ImagesCols a:hover {
  text-decoration: none;
  cursor: pointer;
} */
@media (min-width: 600px) {
  .article-sec-con-desi,
  .article-sec-con-desi1 {
    display: flex;
    gap: 40px;
    margin-top: 50px;
  }
  .article-sec-con-desi .article-sec-con-left {
    width: 60%;
  }
  .article-sec-con-desi .article-sec-con-right {
    width: 40%;
  }

  .article-sec-con-desi1 .article-sec-con-left {
    width: 40%;
  }
  .article-sec-con-desi1 .article-sec-con-right {
    width: 60%;
  }
}
@media (max-width: 600px) {
  .plain-text-row {
    line-height: 30px;
  }
  .row-image-article {
    margin-top: 60px;
  }

  .the-end-sec-content .dash-line {
    width: 70px;
  }
  .manageSiteManagerView1 {
    height: 400px;
  }
  .manageSiteManagerView {
    width: 80%;
    height: 400px;
    margin: auto;
    margin-top: -360px;
  }
  .manageSiteManagerView-aboutus {
    margin-top: 0px;
  }

  /* .topbannerSection {
    padding: 20px 30px;
    background: #000;
  } */

  /* .imageSectionView img{
          object-fit: contain;
      } */
  .imageSectionView,
  .manageSiteManagerView {
    min-height: 400px;
    object-fit: cover;
    height: 400px;
  }
  .CategoryTextLine {
    font-size: 10px;
    height: 15px;
    border: 1px solid #fdd490;
  }
  .bannerCategoryText {
    font-size: 10px;
    line-height: 14px;
    font-style: normal;
  }
  .bannerTitleText {
    font-size: 24px;
    line-height: 33px;
  }
  .bannerSubText {
    font-size: 12px;
    text-align: center;
    line-height: 16px;
  }
  .bannerTextContent {
    margin-top: 110px;
  }
  .share-icon-header-sec {
    margin-top: -380px;
  }
  .BottomSectionView1 {
    margin-top: 0px;
  }
}
/* @media only screen and (max-width: 280px) {
      .ImagesRow{
          width        : 100%;
          margin-left  : 0px;
          margin-right : 0px;
      }
      .ImageCards{
          width   : 30%;
          display : inline-block;
      }
  }
  
  @media all  and (min-width: 360px){
      .ImagesRow{
          width        : 100%;
          margin-left  : 0px;
          margin-right : 0px;
      }
      .ImagesCol{
          width  : 343px;
          height : 281px;
      }
      .ImageCards{
          width   : 40%;
          display : inline-block;
      }
      .ImageMore{
          width  : 100%;
          height : 100%;
      }
  }
  
  @media only screen and (min-width: 411px) {
      .ImageCards{
          width   : 45%;;
          display : inline-block;
      }
  }
  
  @media only screen and (min-width: 540px) {
      .ImageCards{
          width   : 60%;
          display : inline-block;
      }
  }
  
  @media only screen and (max-width:320px) {
      .ImagesRow{
          width        : 100%;
          margin-left  : 0px;
          margin-right : 0px;
      }
      .ImageCards{
          width   : 35%;
          display : inline-block;
      }
  }
  
  @media only screen and (min-width: 768px) {
      .ImagesRow{
          width        : 100%;
          margin-left  : -30px;
      }
      .ImageCards{
          width   : 100%;
          display : inline-block;
      }
  } */
