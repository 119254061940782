.carousel-container {
  width: 100%;
}
.temp-dev {
  height: 200px;
  width: 150px;
  background-color: red;
}
.mobile-view-item {
  /* width: 300px; */
}
.mobile-view-item .carousel-status {
  display: none;
}
.home-page-title-coll {
  text-align: center;
}
.tank-you-page-sec {
  margin-top: 100px;
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
