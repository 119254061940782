.custom_action_slider .react-multi-carousel-list {
  padding-bottom: 102px;
}

.action_slider_content .action_slider_img {
  height: 300px;
  text-align: center;
  background: #fff;
}
.HomePageCollectionsLoading {
  text-align: center;
  margin-left: 70px;
}

.action_slider_content .action_slider_body_content ul {
  list-style: none;
  padding: 8px 0 10px;
  justify-content: center;
}

.action_slider_content .action_slider_body_content ul li {
  font-size: 12px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  border-left: 1px solid #111111;
}

.action_slider_content
  .action_slider_body_content
  ul
  li.action_slider_date_text {
  font-weight: 500;
}

.action_slider_content .action_slider_body_content .action_slider_heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 26px;
  font-family: "Noto Serif", serif;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 42px;
}

.action_slider_content .action_slider_body_content .action_slider_browse_btn {
  font-weight: 500;
  font-size: 12px;
  color: #111111;
  border: 1px solid #111111;
  border-radius: 4px;
  padding: 10px;
  transition: all 0.5s ease-in-out;
  box-shadow: unset;
}

.react-multi-carousel-track {
  margin: 0 -15px;
}

.react-multi-carousel-track .react-multi-carousel-item {
  padding: 0 15px;
}

.react-multiple-carousel__arrow,
.react-multiple-carousel__arrow:hover {
  border: 1px solid rgba(17, 17, 17, 0.3);
  border-radius: 8px;
  background: #fff;
  bottom: 28px;
  right: 29px;
  left: auto;
  font-weight: bold !important;
}
.react-multiple-carousel__arrow--left,
.react-multiple-carousel__arrow--left:hover {
  right: 88px;
}

.react-multiple-carousel__arrow::before {
  font-size: 13px;
  color: #111;
}

.custom_action_slider_section
  .action_slider_content:hover
  .action_slider_body_content
  .action_slider_browse_btn {
  background: #000;
  border-color: #000;
  color: #fff;
}

/* media query css start */

@media (max-width: 991px) {
  .custom_slider_heading_filter
    .custom_slider_heading_filter_content
    .form-group {
    display: none !important;
  }
}

@media (max-width: 600px) {
  .action_slider_content .action_slider_body_content .action_slider_heading {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .action_slider_content .action_slider_body_content .action_slider_browse_btn {
    /* margin: unset !important; */
  }
}

/* media query css end */
.Collection_action_slider_content .action_slider_img {
  height: 300px;
  text-align: center;
  background: #fff;
}
.Collection_action_slider_content .action_slider_img img {
  /* max-width: 100%;
    max-height: 100%; */
  max-height: 300px;
  min-height: 300px;
  object-fit: contain;
}
