.live_auction_flex {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

#dataMessagesTextarea {
  display: none !important;
}

.enterBidAmount {
  height: 60px;
  width: 100%;
}
.loadingBoxCenter {
  text-align: center;
  margin-top: 10px;
}
.enterBidAmount input {
  height: 60px;
  width: 100%;
  padding: 10px;
}

.enterBidAmountInput {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 56px;
  line-height: 56px;
  left: 0;
  bottom: 0;
}

.lotButtonssentBid {
  position: absolute !important;
  display: flex;
  bottom: 0;
  width: 100%;
}

.quicKBidBtn {
  height: 50px;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  width: 60%;
}

.orConditionSpace {
  width: 7%;
  text-align: center;
  padding-top: 12px;
  color: #ada5a5;
}

.PlaceMaxBidBtn {
  width: 33%;
  color: #000;
  border: 1px solid #464444;
  cursor: pointer;
}

.PlaceMaxBidBtn[disabled],
.quicKBidBtn[disabled] {
  cursor: auto;
}

.PlaceMaxBidAmount {
  position: absolute !important;
  /* display: flex; */
  bottom: 0;
  width: 100%;
  display: none;
}

.selectPriceDD {
  width: 100%;
}

.selectPriceDD select {
  width: 100%;
  height: 50px;
  color: #ada5a5;
  padding-left: 10px;
  border: 1px solid #000;
}

.FinalPlaceMaxBidBtn {
  height: 50px;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  width: 50%;
  border: none;
}

.cancelBibButton {
  border: 1px solid #000;
  height: 50px;
  /* background-color: #000; */
  color: #000;
  cursor: pointer;
  width: 50%;
}

/* .lotButtonssentBid input, .lotButtonssentBid button{
    width: 100% !important;
} 
.lotButtonssentBid input{
    justify-content: left !important;
}
.lotButtonssentBid button{
    justify-content: center !important;
} */
.live_auction_page .auction_left_list::-webkit-scrollbar,
.live_auction_page .auction_box_2::-webkit-scrollbar,
.live_auction_page .auction_box_inner_list .reset::-webkit-scrollbar {
  width: 6px;
}

.live_auction_page .auction_left_list::-webkit-scrollbar-track,
.live_auction_page .auction_box_2::-webkit-scrollbar-track,
.live_auction_page .auction_box_inner_list .reset::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.live_auction_page .auction_left_list::-webkit-scrollbar-thumb,
.live_auction_page .auction_box_2::-webkit-scrollbar-thumb,
.live_auction_page .auction_box_inner_list .reset::-webkit-scrollbar-thumb {
  background: #888;
}

.live_auction_page .auction_left_list::-webkit-scrollbar-thumb:hover,
.live_auction_page .auction_box_2::-webkit-scrollbar-thumb:hover,
.live_auction_page
  .auction_box_inner_list
  .reset::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.live_auction_page .auction_box_1.active {
  left: 0;
}

.live_auction_page .auction_box_3.active {
  right: 0;
}

.live_auction_page .auction_box_3.active ~ .right_video_close_bar {
  right: 430px;
}

@media (max-width: 991px) {
  .live_auction_page .auction_box_1,
  .live_auction_page .auction_box_3 {
    position: absolute;
    left: -420px;
    width: 420px;
    z-index: 999;
    background: #fff;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    transition: all 0.5s ease-in-out;
  }

  .live_auction_page .auction_box_2 {
    width: 100%;
    border: 0;
    padding-top: 50px;
  }

  .live_auction_page .auction_box_3 {
    left: auto;
    right: -420px;
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
  }

  .image_gallery_slider.live_action_slider .image-gallery-thumbnail {
    height: 6px;
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    display: none;
  }

  .image_gallery_slider.live_action_slider .image-gallery-thumbnails {
    padding: 20px;
  }

  .live_auction_page .auction_box_1 .auction_left_list,
  .live_auction_page .auction_box_2,
  .live_auction_page .auction_box_3 .auction_box_inner_list ul {
    overflow-y: auto;
  }
}

@media (max-width: 600px) {
  .image_gallery_slider.live_action_slider
    .image-gallery-slide
    .image-gallery-image {
    height: 200px;
  }

  .live_auction_page .auction_box_2 {
    padding: 50px 15px;
  }

  .live_auction_page .auction_box_1,
  .live_auction_page .auction_box_3 {
    left: -100%;
    width: 100%;
  }

  .live_auction_page .auction_box_3 {
    right: -100%;
    left: auto;
    padding-top: 50px;
  }

  .right_video_close_bar {
    z-index: 9999;
    right: -26px;
  }

  .live_auction_page .auction_box_3.active ~ .right_video_close_bar,
  .live_action_right_sidebar_icon {
    right: 10px;
  }

  .live_action_left_sidebar_icon {
    left: 15px;
  }
}

#subscriber {
  height: 275px;
  width: 475px;
}
