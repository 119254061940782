.for_got_password_model .modal-header {
  border-bottom: none;
}
.for_got_password_model .modal-body {
  padding-top: 0px !important;
}
.sub_forgotpassword_text {
  color: #111;
  font-family: Noto Serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.success-message-txt {
  color: green;
}
.sub_sub_forgotpassword_text {
  color: rgba(17, 17, 17, 0.6);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  margin-bottom: 25px;
}
.continue_forgot_password {
  text-align: center;
  margin-top: 40px;
}
.forgot_password_sec_full {
  width: 90%;
  margin: auto;
}
.forgot_password_sec_full.success-link-email {
  text-align: center;
}
.email-success-Logo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgba(17, 17, 17, 0.12);
  margin: auto;
}
.email-success-Logo-img {
  margin-top: -113px;
}
.dont-receive-link-email {
  margin-top: 90px;
}
.for_got_password_model .continue_forgot_password button {
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  background-color: #000;
  line-height: normal;
  letter-spacing: 0.2px;
  width: 300px;
  height: 50px;
}
.forgotpassword_text_error {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}
.dont-receive-link-email span {
  color: var(--red-colour, #8a0f0f);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  cursor: pointer;
}
