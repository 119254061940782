.headerSectionBidWorks {
  /* width: 1440px; */
  height: 180px;
  background-color: #fff1e5;
}

.bidWorkTextDiv {
  /* width: 1160px;
    height:480px; */
  margin: 32px 140px 211px;
  /* text-align: justify; */
  /* font-family: Noto serif;
    font-style: normal; */
}

/* .chevronLeft{
    padding-right: 17px;
} */
.email-field-sec-a {
  text-decoration: underline;
}
.bidWorkBackText {
  font-family: Noto serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  vertical-align: top;
  color: #111111;
  padding-left: 50px;
  padding-top: 27px;
  cursor: pointer;
}

.bidWorksHeading {
  font-family: Noto serif;
  font-weight: 600;
  font-style: normal;
  font-size: 40px;
  line-height: 63px;
  text-align: left;
  vertical-align: top;
  color: #111111;
  padding-left: 50px;
}

.howBidWorkContainer .bidWorkTextDiv div p.howBidWorkHeadingTexts {
  font-family: Noto serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 40px;
  color: #000000;
}

.howBidWorkContainer .bidWorkTextDiv div p.howBidWorkSubHeadingTexts {
  font-family: Noto serif;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  line-height: 40px;
  color: #000000;
}

.howBidWorkContainer .bidWorkTextDiv hr {
  margin: 32px 0px;
  border-top: 1px solid#000000;
}

.howBidWorkContainer .bidWorkTextDiv div p {
  font-family: Noto serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 40px;
  color: #000000;
}

.enquiriesMail,
.assistanceText,
.encourageText {
  margin: 40px 0px;
}
.assisatnceBiddingDiv a {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .chevronLeft {
    padding-right: unset;
  }
  .bidWorkBackText {
    padding-left: 25px !important;
    cursor: pointer;
  }
  .bidWorksHeading {
    padding-left: 25px !important;
    font-size: 24px !important;
    line-height: 33px !important;
    color: #333333;
  }
  .bidWorkTextDiv {
    margin: 32px 16px;
  }
  .howBidWorkContainer .bidWorkTextDiv hr {
    margin: 32px 0px;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }
  .howBidWorkContainer .bidWorkTextDiv div p {
    font-size: 14px;
    line-height: 32px;
  }
  .howBidWorkContainer .bidWorkTextDiv div p.howBidWorkHeadingTexts {
    line-height: 27px;
  }
  .howBidWorkContainer .bidWorkTextDiv div p.howBidWorkSubHeadingTexts {
    font-size: 16px;
    line-height: 22px;
  }
  .howBidWorkContainer
    .bidWorkTextDiv
    div
    p.howBidWorkHeadingTexts.assisatnceBiddingText {
    font-weight: 500;
    line-height: 40px;
  }
  .howBidWorkContainer .bidWorkTextDiv .assisatnceBiddingDiv p {
    font-size: 18px;
    line-height: 40px;
  }
}
