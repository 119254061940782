.auction_box_2 {
  width: 43%;
  padding: 30px;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  overflow-x: hidden;
  height: 100vh;
  overflow-y: hidden !important;
}

.auction_box_2::-webkit-scrollbar {
  display: none;
  overflow-y: scroll;
  /* width: 2px !important;
    background-color: red; */
  padding: 0 !important;
  margin: 0 !important;
}

.auction_box_2:hover {
  overflow-y: scroll !important;
}

.auction_banner_for_main > .image_gallery_slider.live_action_slider {
  /* display: flex !important; */
}
.auction_banner_cont {
  width: 100%;
  word-break: break-word;
}

.image_gallery_slider.live_action_slider .image-gallery-slide.center {
  padding-right: 0;
}

.image_gallery_slider.live_action_slider
  .image-gallery-slide
  .image-gallery-image {
  width: 100% !important;
  max-width: 100%;
  height: 400px !important;
  max-height: unset !important;
  object-fit: contain !important;
}
.image_gallery_slider.live_action_slider
  .image-gallery-thumbnails
  .image-gallery-thumbnails-container {
  justify-content: center;
}

.image-gallery-left-nav svg,
.image-gallery-right-nav svg {
  display: none;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  width: 25px;
  height: 25px;
  padding: 0 !important;
  filter: none;
  top: auto;
  transform: translateY(0%);
  bottom: -100px;
}

.image-gallery-left-nav::before,
.image-gallery-right-nav::before {
  content: "";
  position: absolute;
  background-image: url(../../../assets/image/ar1.png);
  width: 25px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-gallery-left-nav::before {
  background-image: url(../../../assets/image/ar2.png);
}

.image_gallery_slider.live_action_slider .image-gallery-thumbnail {
  margin: 0 10px;
  border-radius: 4px;
  height: 80px;
}

.image_gallery_slider.live_action_slider .image-gallery-thumbnails {
  padding: 40px 30px;
}

.live_action_slider.image_gallery_slider
  .image-gallery-thumbnails-wrapper::before {
  display: none;
}

.live_action_slider.image_gallery_slider .image-gallery-thumbnails-wrapper {
  margin-top: 0;
}

.live_action_slider.image_gallery_slider .image-gallery-thumbnail {
  width: 100px;
}

.auction_box_2 .auction_banner_cont_top h3 {
  font-weight: 600;
  font-size: 24px;
  color: #000000;
  margin: 8px 0px;
  font-family: "Noto Serif", serif;
}

.auction_banner_cont_top .lots_cost span {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  display: block;
  font-family: "Poppins", sans-serif;
}

.auction_banner_cont_top .lots_cost .strong {
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.auction_banner_cont_tabs .product_description {
  padding: 30px 0 0 0;
}

.auction_banner_cont_tabs .product_description .prd_des_list {
  width: 100%;
}

.auction_banner_cont_tabs
  .product_description
  .prd_des_list
  .nav-item:first-child {
  margin-left: 0;
}

.auction_banner_cont_tabs .product_description .tab-content {
  padding: 0;
}

.auction_banner_cont_tabs
  .product_description
  .tab-content
  .prdct_des_tab_cont {
  padding: 20px 0px;
}

.auction_banner_cont_tabs
  .product_description
  .tab-content
  .prdct_des_tab_cont
  p {
  padding-bottom: 25px;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 26px;
  color: #333333;
  font-family: "Noto Serif", serif;
  font-weight: 400;
}

.auction_banner_cont_tabs
  .product_description
  .tab-content
  .prdct_des_tab_cont
  h4 {
  font-size: 20px;
  color: #333333;
  font-weight: 400;
  font-family: "Noto Serif", serif;
  margin-bottom: 0;
}
.live_action_slider .image-gallery-slide.center {
  width: 100% !important;
  height: 500px !important;
  overflow: hidden !important;
}
.live_action_slider .image-gallery-slide.center img {
  width: 80% !important;
}

.description_tabs_content .public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: unset !important;
}

.auction_banner_for_main .image-gallery-slides {
  width: unset !important;
  height: unset !important;
}

.auction_banner_for_main .live_action_slider .image-gallery-swipe {
  display: unset !important;
  justify-content: unset !important;
  width: 100% !important;
}

@media (min-width: 900px) {
  .auction_banner_for_main
    .image_gallery_slider
    .image-gallery-slide.center
    img {
    min-height: 400px !important;
  }
  .auction_banner_for_main .image-gallery-thumbnails-wrapper {
    width: unset !important;
  }
}
