.support_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="support"] {
  color: #000;
  font-weight: 600;
}

.support_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="support"]:hover {
  background: #fff;
}

.support_page_content .support_no_activity {
  font-family: "Noto Serif", serif;
}

.support_page_content .support_no_activity form {
  max-width: 600px;
}

.support_page_content .support_no_activity form .form-group .form-control {
  padding: 0 3px;
  font-size: 14px;
  color: #111111;
  height: 40px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.support_page_content
  .support_no_activity
  form
  .form-group
  textarea.form-control {
  height: auto;
  padding: 6px 12px;
}

.support_page_content .support_no_activity form .form-group .form-label {
  font-size: 14px;
  line-height: 19px;
  color: #111111;
  font-weight: 500;
}

.support_page_content .support_no_activity form button.btn-primary {
  font-size: 16px;
  line-height: 19px;
  padding: 12px 30px;
  border-radius: 6px;
  background: #000;
  border-color: #000;
  color: #fff;
}

.support_page_content .all_acount_heading_dropdown {
  max-width: 50%;
  width: 100%;
  padding: 0 15px;
}

.support_page_content .all_acount_heading_dropdown select {
  padding: 0 3px;
  font-size: 14px;
  color: #111111;
  border-radius: 2px;
  padding-right: 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  height: 40px;
}

.support_page_content .all_acount_heading_dropdown select:focus {
  box-shadow: unset !important;
}

.support_page_content .support_no_activity .accordion .card {
  margin-bottom: 16px;
  border: 1px solid #e7e5e5;
  border-radius: 4px;
}

.support_page_content
  .support_no_activity
  .accordion
  .card
  .card-header
  button {
  padding: 18px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  padding-right: 30px;
  box-shadow: none;
}

.support_page_content
  .support_no_activity
  .accordion
  .card
  .card-header
  button
  p
  span {
  /* border: 1px solid #111111;
  color: #111111;
  font-weight: 600;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 4px;
  line-height: 16px; */
}

.support_page_content
  .support_no_activity
  .accordion
  .card
  .card-header
  button
  p
  span.inprogress_btn {
  color: #fff;
  background-color: #343a40;
}

.support_page_content .support_no_activity .accordion .card .card-body p {
  font-family: "Poppins", sans-serif;
  color: #4e4e4e;
}
.support_page_content .row-item-ticket span.desc-support-text-sec {
  border: none !important;
  padding: 18px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  padding-right: 30px;
  box-shadow: none;
  display: initial;
  margin-left: -15px;
  line-height: 30px;
}
.support_page_content .row-item-ticket span div {
  display: inherit;
  border: none;
  line-height: 40px !important;
}
.support_page_content .bub-bold-sec {
  font-weight: bold !important;
  padding: 0px !important;
  margin-right: 10px;
}
.support_page_content .bub-bold-sec-status {
  font-weight: bold !important;
  background: #000;
  color: #fff;
  padding: 5px 10px !important;
  border-radius: 4px;
}
.support_page_content .bub-bold-sec-status2 {
  border-radius: 3px;
  border: 1px solid #000 !important;
  padding: 5px 10px !important;
  background: #fff;
  color: #000;
}
.replay-sent-sec-content {
  border-radius: 0px 0px 3px 3px;
  background: #fcfcfc;
  box-shadow: 1px -1px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 10px 15px;
  margin-top: 70px;
}
.row-text-item-single-now p {
  border-radius: 8px;
  background: #f6f6f6;
  padding: 16px;
  padding-bottom: 0px;
  padding-left: 0px;
  /* margin-top: 35px; */
}
.row-text-item-single-now p.row-text-item-single-now-date {
  padding-top: 8px;
}
.replay-sent-sec-content input {
  color: #696060;
  font-family: Noto Serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 45px;
  border-radius: 4px;
  border: 1px solid #ececec;
  background: #fff;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0);
  padding-left: 10px;
  width: calc(100% - 65px);
}

.row-text-item-single-now {
  width: 70%;
  border-radius: 8px;
  background: #f6f6f6;
  padding: 16px;
  margin-top: 15px;
  margin-bottom: 20px;
}
.row-text-item-single-now-left {
  display: inline-block;
}
.row-text-item-single-now-right {
  display: inline-block;
  width: 100%;
  /* float: right; */
}
.row-text-item-single-now-left .row-text-item-single-now {
}
.row-text-item-single-now-right .row-text-item-single-now {
  float: right;
}
.replay-sent-sec-content button {
  width: 65px;
  height: 45px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}
@media (max-width: 767px) {
  .support_page_content .support_no_activity h6 {
    font-size: 14px;
    line-height: 18px;
  }
  .support_page_content .support_no_activity form button.btn-primary {
    font-size: 14px;
    line-height: 14px;
    padding: 12px 15px 12px;
  }
  .support_page_content
    .support_no_activity
    .accordion
    .card
    .card-header
    button {
    font-size: 15px;
    line-height: 22px;
    padding: 15px 16px;
  }
  .row-text-item-single-now {
    width: 90%;
  }
}
