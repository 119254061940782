.bids_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="bids"] {
  color: #000;
  font-weight: 600;
}

.bids_page_content
  .more_collection_colum
  .custom_account_list_sidebar
  .list-group-item[title="bids"]:hover {
  background: #fff;
}

.account_all_page {
  padding-bottom: 60px;
}

.account_all_right_content .account_all_right_section {
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 100px;
}

.account_all_right_content_heading {
  font-family: "Noto Serif", serif;
}

.account_all_right_content
  .account_all_right_section
  .account_all_right_content_heading
  h4 {
  font-size: 20px;
  line-height: 27px;
  color: #000;
}

.bids_no_activity {
  width: 680px;
  max-width: 100%;
}

.bids_no_activity p {
  font-family: "Noto Serif", serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  opacity: 0.5;
  margin-top: 20px;
}

.bids_no_activity p a {
  color: #000;
  text-decoration: underline;
}

.account_all_right_content .bids_page_past_bid {
  margin-top: 40px;
}

.responsive_bids_tab .nav-tabs {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  padding-bottom: 22px;
}

.responsive_bids_tab .nav-tabs .nav-link {
  width: 50%;
  background: transparent;
  color: #0000004d;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  padding: 14px 16px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  border-radius: 0;
  font-family: "Noto Serif", serif;
}

.responsive_bids_tab .nav-tabs .nav-link:first-child {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.responsive_bids_tab .nav-tabs .nav-link.active {
  margin-bottom: 0;
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #000;
}

.responsive_bids_tab .tab-content {
  border: 1px solid rgba(233, 141, 141, 0.1);
  border-top: 0;
}

@media (max-width: 767px) {
  .account_all_right_content .account_all_right_section {
    padding: 16px;
  }
  .purchases_mobile_view button {
    border: 0px;
  }
}

@media (max-width: 600px) {
  .bids_no_activity p {
    line-height: 28px;
  }
  .bids_no_activity {
    padding: 0 15px;
  }
}
