.printContainer {
  width: 40%;
  /* margin-left: 31%; */
  margin-top: 40px;
  margin-bottom: 100px;
}

.dotted {
  width: 110%;
  margin-left: -62px !important;
  margin-bottom: 10px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='4%2c 25' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.HeadingText {
  width: 151px;
  height: 27px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #000000;
  text-align: center;
}

dotted blue .collectionHeading {
  font-family: Noto Serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #000000;
}
.geaderNametitle {
  display: block;
}

.HeadingDate {
  /* width         : 194px; */
  /*189px*/
  height: 18px;
  font-family: "Poppins";
  font-style: normal;
  font-size: 12px;
  /* line-height: 100px; */
  line-height: 18px;
  vertical-align: top;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
}

.SaleText {
  font-family: Noto serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  text-align: left;
}

.SaleAllText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
}

.lotsHeadingText {
  margin-top: 40px;
}

.SaleInformationText,
.lotsHeadingText {
  font-family: Noto serif;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 8px !important;
}

.hrRow {
  margin-left: -17px;
  margin-top: 0px !important;
  margin-bottom: 8px !important;
}

.cardHrRow {
  margin-left: -17px;
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}

.ModeColor,
.DateColor,
.TotalLotsColor,
.ContactInformationTextColor {
  color: rgba(0, 0, 0, 0.5);
}

.saleInfoText {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  vertical-align: top;
  letter-spacing: 0.01em;
  margin-bottom: 8px;
}

.ContactInformationText {
  color: rgba(0, 0, 0, 0.5);
}

.ContactInformation {
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0.01em;
}

.ContactInformation > span {
  display: flex;
  flex-direction: column;
}

.linkStyle {
  width: 271px;
  height: 14px;
  font-weight: 400;
  font-family: Noto serif;
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  line-height: 100%;
  text-align: left;
  vertical-align: top;
  color: #000000;
  margin-left: -15px;
}

.pageSpan {
  float: right;
  text-align: right;
}

.pageNumber {
  width: 27px;
  height: 14px;
  font-family: Noto serif;
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  line-height: 100%;
  /* text-align : right; */
  vertical-align: top;
  letter-spacing: 0.02em;
  color: #000000;
  float: right;
}

.hrStyle {
  background-color: #000000;
}

.printCardRow {
  margin-bottom: 20px;
}

.printCard {
  border: none;
}

.printImageSize {
  width: 100px;
  height: 100px;
}

.printLotNumber {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 4px;
}

.printCardTitle {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.printEstimatePrice {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.5);
}
