.add_address_page_content .add_address_form_container {
  max-width: 670px;
  font-family: "Noto Serif", serif;
}

.add_address_page_content .add_address_form_container .breadcrumb {
  background: transparent;
  margin-bottom: 25px;
  padding: 0;
}

.add_address_page_content .add_address_form_container .breadcrumb li a,
.add_address_page_content .add_address_form_container .breadcrumb li {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #333;
  text-decoration: none;
}

.add_address_page_content .add_address_form_container .breadcrumb li.active {
  color: rgba(0, 0, 0, 0.6);
}

.add_address_page_content
  .add_address_form_container
  .breadcrumb
  .breadcrumb-item
  + .breadcrumb-item::before {
  content: ">";
}

.add_address_page_content .add_address_form_container .form-group .form-label {
  font-size: 14px;
  line-height: 19px;
  color: #111111;
  margin: 0 0 8px;
  font-weight: 500;
}

.add_address_page_content
  .add_address_form_container
  .form-group
  select.form-control {
  padding: 0 3px;
  width: auto;
}

.add_address_page_content
  .add_address_form_container
  .form-group
  .form-control {
  height: 40px;
  padding: 6px 12px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 14px;
  color: #111111;
  box-shadow: none;
}

.add_address_page_content
  .add_address_form_container
  .form-group
  .form-control::-webkit-outer-spin-button,
.add_address_page_content
  .add_address_form_container
  .form-group
  .form-control::-webkit-inner-spin-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: none;
}

.add_address_page_content
  .add_address_form_container
  .form-group
  .form-check-label {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #111;
}

.add_address_page_content
  .add_address_form_container
  .form-group
  .form-check-label::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 4px;
  width: 14px;
  height: 14px;
  border: 1px solid #111111;
  border-radius: 2px;
}

.add_address_page_content
  .add_address_form_container
  .form-group
  .form-check-input {
  opacity: 0;
}

.add_address_page_content
  .add_address_form_container
  .form-group
  .form-check-label::after {
  content: "";
  position: absolute;
  top: 6px;
  left: -15px;
  width: 4px;
  height: 8px;
  border: solid #000000;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg) scale(0);
}

.add_address_page_content
  .add_address_form_container
  .form-group
  .form-check-input:checked
  ~ .form-check-label::after {
  transform: rotate(45deg) scale(1);
}

.add_address_page_content .add_address_form_container .add_address_form_btn {
  padding-top: 35px;
  padding-bottom: 15px;
}

.add_address_page_content
  .add_address_form_container
  .add_address_form_btn
  .btn-outline-primary {
  border-color: #000;
  color: #000;
  font-weight: 600;
  border-radius: 4px;
  min-width: 100px;
  font-size: 16px;
  line-height: 19px;
  padding: 12px 15px 12px;
}

.add_address_page_content
  .add_address_form_container
  .add_address_form_btn
  .btn-outline-primary:hover {
  background: #000;
  color: #fff;
}

.add_address_page_content
  .add_address_form_container
  .add_address_form_btn
  .btn-primary {
  font-size: 16px;
  line-height: 19px;
  padding: 12px 30px;
  background: #000;
  border-color: #000;
  color: #fff;
  font-weight: 900;
  border-radius: 6px;
}

.add_address_page_content
  .add_address_form_container
  .add_address_form_btn
  .btn-primary:focus,
.add_address_page_content
  .add_address_form_container
  .add_address_form_btn
  .btn-outline-primary:focus {
  box-shadow: unset !important;
}

.twoBoxAddress {
  margin-left: -109px !important;
}
.emptyLabelAddress {
  margin-top: 16px !important;
}

@media (max-width: 767px) {
  .add_address_page_content
    .add_address_form_container
    .form-group
    select.form-control {
    width: 100%;
  }
  .mobile-number-col {
    width: 140px;
  }
  .mobile-number-col-sec {
    width: calc(100% - 140px);
  }
  .twoBoxAddress {
    margin-left: -74px;
  }
  .add_address_page_content .add_address_form_Page_mobile {
    max-width: 80%;
    margin-top: 40px;
    margin-bottom: 100px;
  }
}
