.sign_up_page_content {
  width: 745px;
  margin: 0 auto;
  max-width: 100%;
}
.emailErrorText {
  color: red;
  margin-top: -20px;
  margin-bottom: 30px;
}
.signup-flow-phone-pe {
}
.signup-flow-phone-pe .mobile-number-col {
  width: 100%;
}
.date-of-birth-field-step2 {
}
.react-datepicker__tab-loop .react-datepicker__year-select,
.react-datepicker__month-select {
  padding: 2px 10px;
  border-radius: 4px;
}
.file-name-sec-account {
  color: #189818;
  font-size: 16px !important;
  font-weight: bold;
}
.cursor-pointer {
  cursor: pointer;
}
.signup-flow-phone-pe .mobile-number-col-sec {
  /* width: 70%; */
}
.add_address_form_container .error-date-sec-dateof-birth {
  border-color: red;
}
.phone-control-fields-sec {
  display: flex;
  gap: 20px;
}
.add_address_form_container .form-group select.form-control {
  cursor: pointer;
}
.phone-control-fields-sec .phone-control-fields-sec-left {
  width: 35%;
}
.phone-control-fields-sec .phone-control-fields-sec-left select {
  padding-left: 5px !important;
  width: 100% !important;
}
.phone-control-fields-sec .phone-control-fields-sec-right {
  width: 65%;
}
.phone-control-fields-sec .phone-control-fields-sec-right input {
  width: 100% !important;
}

.sign_up_page_content .sign_up_tabs_heading {
  padding-bottom: 60px;
}
.signup_info_text .text-signup-aggr-sec a {
  font-weight: 600;
  text-decoration: underline;
}
.text-signup-aggr-sec input {
  height: 17px;
  width: 17px;
  top: 5px;
  position: relative;
  margin-right: 5px;
  cursor: pointer;
  accent-color: #000;
}
.sign_up_page_content .sign_up_tabs_heading h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 54px;
  padding-bottom: 48px;
  font-family: "Noto Serif", serif;
  color: #333333;
  margin-bottom: 0;
}

.sign_up_page_content .sign_up_tabs_heading .sign_up_page_tab_number p {
  padding: 15px 10px 0;
  position: relative;
  min-width: 200px;
  margin-bottom: 0;
}

.sign_up_page_content .sign_up_tabs_heading .sign_up_page_tab_number p span {
  width: 60px;
  height: 60px;
  margin: 0 auto 20px;
  line-height: 60px;
  font-size: 24px;
  font-weight: 600;
  color: #c4c4c4;
  border: 2px solid #c4c4c4;
  border-radius: 50%;
  background: #fff;
  z-index: 2;
}

.sign_up_page_content .sign_up_tabs_heading .sign_up_page_tab_number h5 {
  font-weight: 600;
  color: #c4c4c4;
  font-size: 18px;
  font-family: "Noto Serif", serif;
}

.sign_up_page_content .sign_up_tabs_heading .sign_up_page_tab_number p::after {
  content: "";
  display: block;
  height: 4px;
  width: 50%;
  top: 45px;
  background: #c4c4c4;
  position: absolute;
  right: 0px;
}

.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:first-child::before,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:last-child::after {
  display: none;
}

.sign_up_page_content .sign_up_tabs_heading .sign_up_page_tab_number p::before {
  content: "";
  display: block;
  height: 4px;
  width: 50%;
  top: 45px;
  background: #c4c4c4;
  position: absolute;
  left: 0px;
}

.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:first-child[custom-title="1"]
  span,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:first-child[custom-title="2"]
  span,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:first-child[custom-title="3"]
  span,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:nth-child(2)[custom-title="2"]
  span,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:nth-child(2)[custom-title="3"]
  span,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:nth-child(3)[custom-title="3"]
  span {
  border-color: #111;
  background: #111;
  color: #ffffff;
}

.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:first-child[custom-title="1"]
  h5,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:first-child[custom-title="2"]
  h5,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:first-child[custom-title="3"]
  h5,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:nth-child(2)[custom-title="2"]
  h5,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:nth-child(2)[custom-title="3"]
  h5,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:nth-child(3)[custom-title="3"]
  h5 {
  color: #000;
}

.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:first-child[custom-title="2"]::after,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:first-child[custom-title="3"]::after,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:nth-child(2)[custom-title="2"]::before,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:nth-child(2)[custom-title="3"]::before,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:nth-child(2)[custom-title="3"]::after,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:nth-child(3)[custom-title="3"]::before {
  background: #000;
}

.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p
  span::before {
  content: "";
  position: absolute;
  background-image: url(../../../assets/image/Bottom_arrow.svg);
  width: 14px;
  height: 8px;
  top: -19px;
  display: none;
}

.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:first-child[custom-title="1"]
  span::before,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:nth-child(2)[custom-title="2"]
  span::before,
.sign_up_page_content
  .sign_up_tabs_heading
  .sign_up_page_tab_number
  p:nth-child(3)[custom-title="3"]
  span::before {
  display: block;
}

.sign_up_page_content.add_address_page_content .add_address_form_container {
  max-width: 100%;
}

.sign_up_page_content.add_address_page_content
  .add_address_form_container
  .form-group {
  margin-bottom: 32px;
}

.sign_up_page_content.add_address_page_content
  .add_address_form_container
  .form-group
  .form-text
  a {
  opacity: 0.5;
}

.sign_up_page_content.add_address_page_content
  .add_address_form_container
  .form-group
  .form-text {
  font-size: 12px;
  line-height: 16px;
  color: #000;
  margin: 10px 0 0;
}

.sign_up_page_content[custom-title="1"] .complete_btn,
.add_address_page_content
  .add_address_form_container
  .add_address_form_btn
  .btn-outline-primary.finish_btn[custom-title="1"],
.add_address_page_content
  .add_address_form_container
  .add_address_form_btn
  .btn-outline-primary.finish_btn[custom-title="2"],
.add_address_page_content
  .add_address_form_container
  .add_address_form_btn
  .btn-outline-primary.next_btn[custom-title="3"] {
  display: none;
}

.sign_up_page_content[custom-title="1"] .next_btn {
  margin-left: auto !important;
}

.signup_info_text h5 {
  font-size: 18px;
  color: #333333;
  font-weight: 600;
  padding: 12px 0 8px;
  font-family: "Noto Serif", serif;
}

.signup_info_text .select_address_content .form-check .form-check-label {
  line-height: 24px;
  font-size: 14px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  color: #4e4e4e;
}

.signup_info_text .select_address_content .form-check {
  padding-left: 30px;
}

.signup_info_text
  .select_address_content
  .form-check
  .form-check-label::before {
  left: -29px;
}
.signup_info_text .select_address_content .form-check .form-check-label::after {
  left: -26px;
}

.signup_info_text .select_address_content {
  padding-bottom: 20px;
}

.signup_info_text p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  padding-bottom: 20px;
  font-family: "Poppins", sans-serif;
  color: #333333;
  color: #111;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}
.select_address_content1 {
  margin-top: 0px;
}
.signup_info_text p a {
  color: #333333;
  /* text-decoration: none; */
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker-wrapper input {
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 14px;
  color: #111111;
  box-shadow: none;
}

.sign_up_page_content .form-group .input-group-text {
  color: #495057;
  border-radius: 2px 0 0 2px;
  border-color: rgba(0, 0, 0, 0.3);
}

.sign_up_page_content h6 {
  font-size: 16px;
  line-height: 28px;
  font-family: "Noto Serif", serif;
  font-weight: 400;
  padding: 0 0 32px;
}

.sign_up_page_content h6 a {
  font-weight: 700;
  text-decoration: underline;
  color: #333333;
}

.MobileRegistrationText {
  font-style: normal;
  font-size: 10px !important;
  line-height: 18px !important;
}

.MobileRegistrationLinkText {
  font-family: Noto serif;
  font-style: normal;
  font-size: 11px;
  line-height: 18px;
}

.sign_up_page_content .form-file .form-file-label {
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: #111111;
  font-family: "Noto Serif", serif;
  margin: 0 0 8px;
  font-weight: 500;
}

.sign_up_page_content .form-file {
  margin-bottom: 32px;
}

.sign_up_page_content .custom_upload_file {
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
  border-radius: 2px;
  min-height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sign_up_page_content .custom_upload_file p {
  margin-bottom: 0;
  font-size: 12px;
  margin-top: 6px;
}

.sign_up_page_content .custom_upload_file .form-control-file {
  position: absolute;
  width: 100%;
  height: 100%;
  outline: none;
  /* visibility: hidden; */
  opacity: 0;
  cursor: pointer;
}

.sign_up_page_content .custom_upload_file button {
  font-size: 14px;
  line-height: 14px;
  padding: 12px 15px 12px;
  font-weight: 500;
  min-width: 110px;
  border-radius: 4px;
  background: #fff;
  border-color: #000;
  color: #000;
}

.add_address_page_content
  .add_address_form_container
  .add_address_form_btn
  .btn-outline-primary.next_btn[custom-title="3"]
  ~ .finish_btn {
  display: block !important;
}

.add_address_page_content
  .add_address_form_container
  .signup_info_mobile
  .form-group
  .form-check-label::after {
  top: 6px;
  left: -14px;
  width: 6px;
  height: 11px;
  border-width: 0 3px 3px 0;
}

.add_address_page_content
  .add_address_form_container
  .signup_info_mobile
  .form-group
  .form-check-label::before {
  width: 18px;
  height: 18px;
  border: 2px solid #111111;
  border-radius: 0;
}

.add_address_page_content
  .add_address_form_container
  .signup_info_mobile
  .link_input_checkbox
  .form-check-input {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  height: 17px;
  width: 29%;
}

.add_address_page_content
  .add_address_form_container
  .form-group
  .form-check-label
  a {
  text-decoration: underline;
}

.already_account_text {
  padding-top: 32px;
  padding-bottom: 50px;
}
.already_account_text p span {
  text-decoration: underline;
  font-weight: bold;
}

.already_account_text p,
.already_account_text p a {
  font-size: 15px;
  line-height: 20px;
  color: #5b5b5b;
  padding: 0px;
  font-family: "Noto Serif", serif;
}

.add_address_page_content
  .add_address_form_container
  .add_address_form_btn[custom-title="1"] {
  display: none !important;
}

.add_address_page_content
  .add_address_form_container
  .add_address_form_btn
  .mobile_complete_btn {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  text-decoration: underline;
  font-family: "Noto Serif", serif;
}

.add_address_page_content
  .add_address_form_container
  .add_address_form_btn
  .mobile_complete_btn:hover {
  background: #fff;
  color: #000;
}

.add_address_page_content
  .add_address_form_container
  .complete_btn[custom-title="1"],
.add_address_page_content
  .add_address_form_container
  .next_btn[custom-title="1"] {
  display: none !important;
}

.add_address_page_content
  .add_address_form_container
  .next_popup_btn[custom-title="1"] {
  display: block !important;
}

.not_fill_input_modal h4 {
  font-size: 24px;
  line-height: 40px;
  font-weight: 400;
  color: #9e1616;
  margin-bottom: 16px;
}

.not_fill_input_modal h5 {
  font-size: 18px !important;
}
.twoBoxStyles {
  margin-left: -8em;
}
@media (max-width: 767px) {
  .sign_up_page_content .sign_up_tabs_heading .sign_up_page_tab_number p span {
    width: 32px;
    height: 32px;
    line-height: 30px;
    font-size: 10px;
    font-weight: 400;
    background: #ececec;
    border: 2px solid #ececec;
  }
  .sign_up_page_content .sign_up_tabs_heading .sign_up_page_tab_number p {
    padding-top: 0;
    min-width: 33.333%;
  }
  .sign_up_page_content .sign_up_tabs_heading .sign_up_page_tab_number p::after,
  .sign_up_page_content
    .sign_up_tabs_heading
    .sign_up_page_tab_number
    p::before {
    border: 1px dashed #ececec;
    background: transparent;
    height: auto;
    top: 16px;
  }
  .sign_up_page_content
    .sign_up_tabs_heading
    .sign_up_page_tab_number
    p::before {
    left: 3px;
  }
  .sign_up_page_content .sign_up_tabs_heading .sign_up_page_tab_number h5 {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    height: 36px;
  }
  .sign_up_page_content
    .sign_up_tabs_heading
    .sign_up_page_tab_number
    p
    span::before {
    display: none !important;
  }
  .sign_up_page_content .sign_up_tabs_heading h4 {
    font-weight: 500;
    color: #000;
    padding: 0 0 5px;
    font-size: 20px;
    font-family: "Noto Serif", serif;
    margin-bottom: 0;
  }
  .sign_up_page_content .sign_up_tabs_heading h6 {
    font-family: "Noto Serif", serif;
    padding: 0 0 32px;
    font-size: 12px;
    line-height: 19px;
    color: #5b5b5b;
    font-weight: 400;
  }
  .signup_info_text .select_address_content {
    max-width: 100%;
  }
  .sign_up_page_content
    .sign_up_tabs_heading
    .sign_up_page_tab_number
    p:first-child[custom-title="2"]::after,
  .sign_up_page_content
    .sign_up_tabs_heading
    .sign_up_page_tab_number
    p:first-child[custom-title="3"]::after,
  .sign_up_page_content
    .sign_up_tabs_heading
    .sign_up_page_tab_number
    p:nth-child(2)[custom-title="2"]::before,
  .sign_up_page_content
    .sign_up_tabs_heading
    .sign_up_page_tab_number
    p:nth-child(2)[custom-title="3"]::before,
  .sign_up_page_content
    .sign_up_tabs_heading
    .sign_up_page_tab_number
    p:nth-child(2)[custom-title="3"]::after,
  .sign_up_page_content
    .sign_up_tabs_heading
    .sign_up_page_tab_number
    p:nth-child(3)[custom-title="3"]::before {
    background-color: transparent;
    border-color: #000;
  }
  .add_address_page_content
    .add_address_form_container
    .add_address_form_btn
    .btn-outline-primary[custom-title="1"],
  .add_address_page_content
    .add_address_form_container
    .add_address_form_btn
    .btn-outline-primary[custom-title="2"],
  .add_address_page_content
    .add_address_form_container
    .add_address_form_btn
    .btn-outline-primary[custom-title="3"],
  .add_address_page_content
    .add_address_form_container
    .add_address_form_btn
    .btn-outline-primary.next_btn[custom-title="3"]
    ~ .finish_btn {
    background: #000;
    border-color: #000;
    color: #fff;
  }
  .add_address_page_content
    .add_address_form_container
    .add_address_form_btn
    .btn-outline-primary[custom-title="1"] {
    margin-right: auto !important;
    width: 298px;
    max-width: 100%;
  }
  .add_address_page_content
    .add_address_form_container
    .form-group
    .form-check-label {
    padding-left: 11px;
    font-size: 12px;
    line-height: 20px;
    font-family: "Noto Serif", serif;
    color: #5b5b5b;
  }
  .already_account_text[custom-title="2"],
  .already_account_text[custom-title="3"] {
    display: none !important;
  }
  .sign_up_page_content .custom_upload_file {
    background: #efefef;
    border: 1px dashed #bebebe;
    box-sizing: border-box;
    border-radius: 6px;
  }
  .sign_up_page_content .custom_upload_file button {
    background: #000;
    border-color: #000;
    color: #fff;
  }
  .add_address_page_content
    .add_address_form_container
    .add_address_form_btn[custom-title] {
    padding-bottom: 50px;
  }
  .mobile-number-col {
    width: 140px;
  }
  .mobile-number-col-sec {
    width: calc(100% - 140px);
  }
  .twoBoxStyles {
    margin-left: -74px;
  }
}
.login_error_msg {
  color: red;
}
.next_popup_btn_signup_flow:disabled {
  opacity: 0.5;
}
.asterisk-symbol-red {
  color: red;
}

@media (max-width: 767px) {
  .sign_up_page_content {
    max-width: 90%;
    width: 90%;
  }
  .signup_info_text .select_address_content .form-check {
    padding-left: 10px;
  }
  .signup_info_text
    .select_address_content
    .form-check
    .form-check-label::before {
    left: -20px;
  }
  .signup_info_text
    .select_address_content
    .form-check
    .form-check-label::after {
    left: -17px;
  }
}
