@import "~react-image-gallery/styles/css/image-gallery.css";

.image_gallery_slider .image-gallery-slide.center {
  /* padding-right: 16.5%; */
  /* width: 900px;
    height: 555px;
    overflow: hidden; */
  /* height: 100%; */
  width: 100%;
  height: 100%;
  /* padding-left: 180px; */
}
.image_gallery_slider .image-gallery-slide.center img {
  max-height: 100%;
  max-width: 100%;
  object-fit: fill;
}

.image_gallery_slider .image-gallery-slide .image-gallery-image {
  object-fit: cover;
  cursor: zoom-in;
}

.image_gallery_slider .image-gallery-thumbnails {
  /* background: #fff; */
}

.image_gallery_slider .image-gallery-thumbnails-wrapper::before {
  content: "";
  position: absolute;
  right: -1px;
  top: 0px;
  height: 100%;
  width: 100%;
  background: #fff;
}

.noTransform,
.image-gallery-thumbnails-container {
  /* transform: inherit !important; */
}

/* .image_gallery_slider .image-gallery-thumbnails-wrapper{
    margin-top: -27px;
} */

.image_gallery_slider .image-gallery-thumbnail {
  height: 100px;
  /* width: 214px; */
  min-width: 100px;
  border: 0;
  margin-right: 45px;
  border-radius: 4px 4px 0px 0px;
  background: #c4c4c4;
  overflow: hidden;
}

.image_gallery_slider .image-gallery-thumbnail:last-child {
  margin-right: 0;
}

.image_gallery_slider .image-gallery-thumbnail .image-gallery-thumbnail-inner {
  height: 100%;
}

.image_gallery_slider .image-gallery-thumbnail .image-gallery-thumbnail-image {
  object-fit: contain;
  height: 100%;
  background-color: #fff;
}
.image_gallery_slider .image-gallery-thumbnails {
  padding: 40px 50px;
}

.image_gallery_slider
  .image-gallery-thumbnails
  .image-gallery-thumbnails-container {
  text-align: left;
  display: flex;
}
.image_gallery_slider .image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  border: 0;
}

.bid_img_gallery_slider.image_gallery_slider
  .image-gallery-slide.center
  .image-gallery-image {
  opacity: 1;
}

.bid_img_gallery_slider.image_gallery_slider
  .image-gallery-slide
  .image-gallery-image {
  /* opacity: 0.6; */
  transition: opacity 0.1s ease-in-out;
}
.bids_left_component .carousel .carousel-inner {
  background: #fff9f9;
}
/* media query css start */
@media (min-width: 900px) {
  .image_gallery_slider .image-gallery-thumbnail {
    min-width: 100px;
  }
  .image-gallery-thumbnails-wrapper {
    width: 100%;
  }
  .image_gallery_slider
    .image-gallery-thumbnails
    .image-gallery-thumbnails-container {
    width: 98%;
  }
  .image_gallery_slider
    .image-gallery-thumbnails
    .image-gallery-thumbnails-container:hover {
    overflow-y: hidden;
  }
  .image-gallery-thumbnails-container::-webkit-scrollbar {
    height: 4px;
    width: 4px !important;
    /* background: gray;   */
  }
  .image-gallery-thumbnails-container::-webkit-scrollbar-thumb:horizontal {
    background: #ccc;
    border-radius: 10px;
  }
  .image_gallery_slider .image-gallery-thumbnails-wrapper::before {
    /* right: -11px; */
  }
  .image_gallery_slider .image-gallery-thumbnails {
    padding: 40px 0px 0px 50px;
  }
  /* .image-gallery-slides{
        min-width: 647px;
        min-height: 488px;
        max-width: 647px;
        max-height: 488px;
    } */
  .image_gallery_slider .image-gallery-slide.center img {
    /* min-width: 647px; */
    min-height: 488px;
    max-width: 647px;
    max-height: 488px;
    /* max-width: 762px;
        max-height: 550px; */
    object-fit: contain;
  }
}

@media (max-width: 1200px) {
  .image_gallery_slider .image-gallery-thumbnail {
    width: 165px;
  }
}

@media (max-width: 991px) {
  .image_gallery_slider .image-gallery-thumbnails {
    padding: 12px;
  }
  .image_gallery_slider
    .image-gallery-thumbnails
    .image-gallery-thumbnails-container {
    justify-content: center;
  }
  .image_gallery_slider .image-gallery-thumbnail {
    width: 16px;
    height: 6px;
    margin: 6px;
    border-radius: 10px;
  }
  .image_gallery_slider
    .image-gallery-thumbnail
    .image-gallery-thumbnail-image {
    display: none;
  }
  .image_gallery_slider .image-gallery-thumbnail.active {
    background: #767676;
  }
  .image_gallery_slider .image-gallery-thumbnails-wrapper {
    margin-top: 0;
  }
}
.image-gallery-slides {
  width: 85% !important;
  height: 10% !important;
  padding-bottom: 50px;
  margin: auto;
}
@media (max-width: 600px) {
  .mobile-view-item .carousel-root .control-dots {
    bottom: 35px;
  }
  .mobile-view-item .carousel-root .control-dots .dot {
    background: #000;
  }
}
/* media query css end */
