.description_tabs_content {
  padding: 30px 50px;
  display: inline-block;
}
.description_tabs_content .prd_des_list {
  width: 70%;
  border-bottom: 1px solid #c4c4c4;
}

.description_tabs_content .prd_des_list .nav-item {
  margin-left: 50px;
  border: 0;
  font-family: "Noto Serif", serif;
  font-style: normal;
  color: #333333;
  font-weight: 600;
  font-size: 18px;
  display: block;
  padding: 0;
  padding-bottom: 10px;
  line-height: 25px;
  position: relative;
  opacity: 0.4;
  background: transparent;
}

.description_tabs_content .prd_des_list .nav-item.active::before {
  content: "";
  background: #212121;
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.description_tabs_content .prd_des_list .nav-item.active {
  opacity: 1;
}

.description_tabs_content .tab-content {
  padding: 20px 50px;
  font-family: "Noto Serif", serif;
}

.description_tabs_content .tab-content .des_tab_cont p {
  padding-bottom: 25px;
  color: #333333;
}

.description_tabs_content .tab-content .des_tab_cont h4 {
  font-size: 20px;
  color: #333333;
  font-weight: 400;
}

.des_tab_cont .public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: unset !important;
}
.tabs-content-section-view-main {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #111111;
  /* margin-bottom: 30px; */
}
.tabs-content-section-view {
  margin-bottom: 20px;
  margin-top: 48px;

  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;

  color: #111111;
}
.other-items-list-by-sec > li > div {
  display: -webkit-inline-box;
  line-height: 35px;
}
.other-items-list-by-sec .other-item-key-val {
  width: 170px;
  /* font-weight: bold; */
}
.other-items-list-by-sec .other-item-collen-val {
  width: 50px;
  text-align: center;
}
/* media query css start */

@media (max-width: 991px) {
  .description_tabs_content .tab-content {
    padding: 20px 15px;
  }
  .description_tabs_content .prd_des_list {
    width: 100%;
  }
  .description_tabs_content .prd_des_list .nav-item {
    margin-left: 32px;
  }
  .description_tabs_content .prd_des_list .nav-item:first-child {
    margin-left: 15px;
  }
}

@media (max-width: 600px) {
  .description_tabs_content .prd_des_list .nav-item {
    font-weight: 500;
    font-size: 14px;
  }
  .description_tabs_content .tab-content .des_tab_cont p {
    font-size: 14px;
  }
  .tabs-content-section-view {
    margin-top: 30px;
  }
  .description_tabs_content {
    padding: 30px 20px;
  }
}

/* media query css end */
