.contactUsContainer {
  min-height: 500px;
  width: 80%;
  margin: auto;
  margin-top: 60px;
}

.contact-us-main-header {
  color: #263238;
  text-align: center;
  font-size: 46px;
  font-family: Noto Serif;
  font-weight: 700;
  line-height: 70px;
}
.contact-us-box-header {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(32, 32, 32, 0.08);
  margin-top: 42px;
}
.contact-us-box-header-content {
  text-align: center;
  padding: 50px;
  width: 50%;
  margin: auto;
}

.contact-us-box-header-content .email-text {
  color: #292929;
  font-size: 16px;
  font-family: Noto Serif;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.25px;
  margin-bottom: 12px;
}

.contact-us-box-header-content .sub-text {
  color: #292929;
  text-align: center;
  font-size: 14px;
  font-family: Noto Serif;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.25px;
  margin-bottom: 22px;
}
.contact-us-box-header-content a {
  text-decoration: underline;
}
.contact-us-box-header-content button {
  border-radius: 4px;
  border: 1px solid #111;
  background: #111;
  color: #fff;
  font-size: 16px;
  font-family: Noto Serif;
  font-weight: 600;
  height: 50px;
  width: 100%;
}

@media (max-width: 600px) {
  .contact-us-box-header-content {
    width: 80%;
  }
  .contact-us-box-header-content {
    padding: 40px 20px;
  }
}
