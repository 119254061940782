.lots_slider_content .action_slider_body_content .action_slider_heading {
  font-size: 16px;
  margin-bottom: 16px;
  margin-top: 8px;
}
.lots_slider_content
  .action_slider_body_content
  .lots_slider_text_btn
  .lots_slider_text
  span {
  font-size: 12px;
  color: rgba(17, 17, 17, 0.5);
}

.lots_slider_content
  .action_slider_body_content
  .lots_slider_text_btn
  .lots_slider_text
  p {
  font-size: 12px;
  color: #111111;
}

.lots_slider_content .action_slider_body_content .action_slider_browse_btn {
  padding: 8px 10px;
}

.lots_slider_content .action_slider_body_content .action_slider_browse_btn svg {
  margin-right: 6px;
}

.lots_slider_content .action_slider_img {
  height: 300px;
  height: 300px;
  background: #ededed;
  cursor: pointer;
}

.lots_slider_content .action_slider_img img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  background: #fff;
}

.slid_view_all .slid_view_all_btn {
  background: #333333;
  border: 1px solid #3c3a39;
  padding: 5px 10px;
  border-radius: 4px;
}

/* media query css start */

@media (max-width: 767px) {
  .lots_slider_content .action_slider_img {
    max-height: 200px;
    min-height: 200px;
  }
}

/* media query css end */
