#showUserMessageDivID1 {
  width: 300px;
  border: none;
  border-radius: 3px;
  background-color: #000;
  color: #fff;
  min-height: 50px !important;
  display: none;
  flex-direction: row-reverse;
  text-align: center;
  align-items: center;
  position: fixed;
  bottom: 10px;
  left: 20px;
  z-index: 999;
}
.body-container-sec {
  min-height: 500px;
}
#showUserMessageDivID {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 5px 23px 5px;
  margin: 0px !important;
}

.closeIconStyles {
  position: absolute;
  top: 6px;
}

.showUserMobile {
  width: 90% !important;
}
.banner-notification-sec {
  display: grid;
  transition: 0.2s ease-out;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  text-align: center;
  /* position: fixed; */
  z-index: 100;
  width: 100%;
  top: 30px;
  background-color: #ef9b29;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #fff;
  height: 50px;
  color: #fff;
  font-family: Noto Serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.banner-notification-sec1 {
  display: block;
}
