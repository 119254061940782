.word_slider_content .carousel-inner .carousel-item .carousel-caption {
  left: 0;
  right: auto;
  bottom: auto;
  /* padding: 54px 44px; */
  padding: 54px 10px 54px 44px;
}

.word_slider_content .carousel-inner .carousel-item .slider_img img {
  object-fit: cover;
  min-height: 100%;
}
.slider_img {
  max-width: 885px;
  min-width: 885px;
  margin-top: -56px;
}

.word_slider_section .word_slider_bg_section {
  background: #000;
}

.word_slider_section .word_slider_bg_section .carousel-inner {
  overflow: visible;
}

.word_slider_section .custom_slider_heading_filter {
  padding-bottom: 88px;
}

.word_slider_section .word_slider_bg_section .word_slider_content {
  padding-bottom: 88px;
}

.word_slider_content .carousel-inner .carousel-item .carousel-caption h3 {
  font-weight: 600;
  font-size: 42px;
  font-family: "Noto Serif", serif;
  margin-bottom: 9px;
  max-height: 219px;
  color: #fff;
  font-size: 60px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.word_slider_bg_section .carousel-indicators {
  display: none;
}
.word_slider_content .carousel-inner .carousel-item .carousel-caption p {
  color: #111111;
  font-family: "Noto Serif", serif;
  margin-bottom: 40px;
  color: #fafafa;
  font-size: 20px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.word_slider_content .carousel-inner .carousel-item .carousel-caption a {
  color: #111111;
  border: 1px solid #111111;
  font-weight: 500;
  font-size: 24px;
  padding: 14px 20px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  border: 1px solid #fff;
  background: #fff;
  color: #111;
  font-size: 24px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.96px;
}
.word_slider_content .View_class_nmae {
  /* text-decoration: underline !important; */
}
.word_slider_content .carousel-inner .carousel-item .carousel-caption a:hover {
  /* background: #111;
  color: #fff; */
}

.word_slider_content .carousel-inner .carousel-item .carousel-caption span {
  display: block;
  padding-left: 168px;
  font-weight: 600;
  color: #111111;
  font-family: "Noto Serif", serif;
  padding-bottom: 24px;
  min-height: 48px;
}

.word_slider_content .carousel .carousel-control-next,
.word_slider_content .carousel .carousel-control-prev {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  opacity: 1;
  right: auto;
  left: 929px;
  top: auto;
  bottom: 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.word_slider_content .carousel .carousel-control-next {
  left: 980px;
}

.word_slider_content .carousel .carousel-control-next span,
.word_slider_content .carousel .carousel-control-prev span {
  display: none;
}

.word_slider_content .carousel .carousel-control-next::before,
.word_slider_content .carousel .carousel-control-prev::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 25px;
  content: "\2228";
  /* background-repeat: no-repeat !important; */
  /* background: url(../../../assets/image/Bottom_arrow.svg); */
}

.word_slider_content .carousel .carousel-control-next::before {
  transform: rotate(-90deg);
}

.word_slider_content .carousel .carousel-control-prev::before {
  transform: rotate(90deg);
}

.word_slider_content .carousel-indicators li {
  background: #ffffff;
  border: 1px solid #111111;
  opacity: 1;
  height: 6px;
}

.word_slider_content .carousel-indicators li.active {
  background: #111111;
}

.word_slider_content .carousel-indicators {
  margin: 0;
  left: 929px;
  bottom: auto;
  right: auto;
  top: 226px;
}

@media (max-width: 1440px) {
  .slider_img {
    max-width: 830px;
    min-width: 830px;
  }
  .word_slider_content .carousel-indicators {
    left: 874px;
    right: auto;
  }
  .word_slider_content .carousel .carousel-control-prev {
    left: 874px;
  }
  .word_slider_content .carousel .carousel-control-next {
    left: 924px;
  }
}

@media (max-width: 1366px) {
  .slider_img {
    max-width: 60%;
    min-width: 60%;
  }
  .word_slider_content .carousel-indicators,
  .word_slider_content .carousel .carousel-control-prev {
    left: calc(60% + 44px);
  }
  .word_slider_content .carousel .carousel-control-next {
    left: calc(60% + 93px);
  }
}

@media (max-width: 1300px) {
  .slider_img {
    max-width: 700px;
    min-width: 700px;
  }
  .word_slider_content .carousel-indicators {
    left: 740px;
  }
  .word_slider_content .carousel .carousel-control-prev {
    left: 744px;
  }
  .word_slider_content .carousel .carousel-control-next {
    left: 795px;
  }
  .word_slider_content .carousel .carousel-control-next,
  .word_slider_content .carousel .carousel-control-prev {
    bottom: -48px;
  }
}

@media (max-width: 1200px) {
  .slider_img {
    max-width: 620px;
    min-width: 620px;
  }
  .word_slider_content .carousel-indicators,
  .word_slider_content .carousel .carousel-control-prev {
    left: 665px;
  }
  .word_slider_content .carousel .carousel-control-next {
    left: 715px;
  }
}
@media (max-width: 1100px) {
  .slider_img {
    max-width: 510px;
    min-width: 510px;
  }
  .word_slider_content .carousel-indicators,
  .word_slider_content .carousel .carousel-control-prev {
    left: 552px;
  }
  .word_slider_content .carousel .carousel-control-next {
    left: 600px;
  }
}
@media (min-width: 900px) {
  .wordLineClamp {
    max-height: 250px !important;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
  .hoverText {
    color: #fff !important;
    background: #000;
    width: 77%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin: 0 !important;
    top: 7px;
    padding: 3px 4px 0px 0px !important;
    z-index: 999;
  }
}
@media (max-width: 991px) {
  .word_slider_section .word_slider_bg_section {
    /* background: transparent; */
  }
  .word_slider_section {
    margin-bottom: 50px;
  }
  .slider_img {
    min-width: 100%;
    max-width: 100%;
    max-height: 350px;
    overflow: hidden;
  }
  .word_slider_content .carousel-indicators {
    display: none;
  }
  .word_slider_content .carousel-inner .carousel-item .carousel-caption span {
    padding: 0%;
    min-height: auto;
  }
  .word_slider_content .carousel-inner .carousel-item .carousel-caption {
    padding: 16px 0;
  }
  .word_slider_content .carousel .carousel-control-next,
  .word_slider_content .carousel .carousel-control-prev {
    right: 0;
    left: auto;
  }
  .word_slider_content .carousel .carousel-control-prev {
    right: 47px;
  }
  .word_slider_content .carousel-inner .carousel-item .carousel-caption h3 {
    font-size: 44px;
  }
}

@media (max-width: 767px) {
  .word_slider_content .carousel-inner .carousel-item .carousel-caption h3 {
    font-size: 36px;
  }
  .word_slider_content .carousel-inner .carousel-item .carousel-caption p {
    font-size: 14px;
  }
  .word_slider_content .carousel-inner .carousel-item .carousel-caption span {
    font-size: 12px;
  }
  .wordSliderTitle {
    height: 55px !important;
  }
}

@media (max-width: 600px) {
  .slider_img {
    max-height: 240px;
  }
  .word_slider_content .carousel-inner .carousel-item .carousel-caption h3 {
    font-size: 20px;
  }
}
/* media query css end */
