.brows_banner_content {
  padding: 30px 0;
  background: #fff1e5;
  margin-bottom: 40px;
}

.brows_banner_back_btn {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  font-family: "Noto Serif", serif;
  color: #333333;
}
.browse_banner_list_AddC {
  background-color: #fff1e5 !important;
  border: none !important;
  padding-left: 0px !important;
  box-shadow: unset !important;
}
.closses-time-all-sec-left2 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.closses-time-all-sec-left2 p {
  margin-bottom: 2px;
}
.collection-closses-time-all-sec {
  display: flex;
  gap: 10px;
  font-weight: bold;
}
.collection-closses-time-all-sec1 {
  font-weight: bold;
}
.collection-closses-time-all-sec-right p {
  margin-bottom: 2px;
}
.past-auctions-text-con {
  width: 60%;
}
.past-auctions-text-con p {
  font-size: 16px;
}
.inner_banner_content .brows_banner_left_content .auctions_details ul {
  list-style: none;
  margin: 10px 0;
}

.inner_banner_content .brows_banner_left_content .auctions_details ul li {
  font-size: 12px;
  line-height: 18px;
  color: #333333;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  padding: 0 8px;
}
.inner_banner_content
  .brows_banner_left_content
  .auctions_details
  ul
  li.strong {
  font-weight: 500;
  padding-left: 0;
}

.inner_banner_content
  .brows_banner_left_content
  .auctions_details
  ul
  li::before {
  content: "";
  height: 18px;
  width: 1px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background: #333333;
}

.inner_banner_content
  .brows_banner_left_content
  .auctions_details
  ul
  li:first-child:before {
  display: none;
}

.inner_banner_content .brows_banner_left_content .auctions_details h3 {
  font-family: "Noto Serif", serif;
  font-weight: 600;
  font-size: 46px;
  line-height: 63px;
  color: #333333;
  margin-bottom: 35px;
}

.inner_banner_content .brows_banner_left_content .auctions_details h6 {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  font-family: "Poppins", sans-serif;
}

.inner_banner_content
  .brows_banner_left_content
  .auctions_details
  .auction_btn_banner {
  padding: 14px 30px 14px 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  background: #000000;
  color: #ffffff;
  border: none;
  box-shadow: unset !important;
}

.registeredbtns.btn-primary:not(:disabled):not(.disabled).active,
.registeredbtns.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: unset !important;
}

.btn_spacing {
  margin-left: 15px;
}

.onHoverRegister:focus,
.onHoverRegister:active {
  background: #000 !important;
  border: none !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 8%) inset !important;
}

.inner_banner_content .brows_banner_right_content ul {
  list-style: none;
}

.inner_banner_content .brows_banner_right_content ul li {
  margin-bottom: 25px;
}

.inner_banner_content .brows_banner_right_content .browse_banner_list {
  color: #333;
}

.inner_banner_content
  .brows_banner_right_content
  .browse_banner_list
  span.icon_share {
  min-width: 40px;
  height: 40px;
  border: 2px solid #333;
}

.inner_banner_content
  .brows_banner_right_content
  .browse_banner_list
  span.icon_share
  img {
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.inner_banner_content .brows_banner_right_content .browse_banner_list h5 {
  font-size: 15px;
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.bannerInfoIcon {
  margin-left: 7.5px;
}

.hoverInfo {
  display: none;
  width: 115px;
  height: 82px;
  background: #ffffff;
  color: #000000;
  text-align: center;
}

.hoverInfo p {
  margin-bottom: 7px;
}

.bannerInfoIcon:hover + .hoverInfo {
  display: block;
  position: absolute;
  bottom: 31px;
  right: 0;
}

.yourLocalTime {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #111111;
}

@media (max-width: 991px) {
  .inner_banner_content .brows_banner_right_content ul {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    z-index: 99;
    background: #fff;
    width: 95%;
    transition: all 0.5s ease-in-out;
  }
  .inner_banner_content .brows_banner_right_content {
    margin-top: 30px;
  }
  .inner_banner_content
    .brows_banner_right_content
    .mobile_open_browse_list_background {
    position: fixed;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background: #ccc;
    opacity: 0.4;
    z-index: 99;
    transition: all 0.5s ease-in-out;
  }
  .inner_banner_content .brows_banner_right_content.active ul {
    transform: translate(-50%, -50%) scale(1);
  }
  .inner_banner_content
    .brows_banner_right_content.active
    .mobile_open_browse_list_background {
    width: 100%;
  }
  .inner_banner_content .brows_banner_right_content ul li {
    margin-bottom: 0;
  }
  .inner_banner_content .brows_banner_right_content ul li a {
    padding: 10px 20px;
    border-bottom: 1px solid #f1f0f0;
    text-transform: uppercase;
  }
  .inner_banner_content .brows_banner_right_content .browse_banner_list h5 {
    font-size: 13px;
  }
  .inner_banner_content
    .brows_banner_right_content
    ul
    li
    .browse_banner_list
    span.icon_share {
    min-width: 24px;
    border: 1px solid #000;
    height: 24px;
  }
  .inner_banner_content
    .brows_banner_right_content
    ul
    li
    .browse_banner_list
    span.icon_share
    img {
    width: 14px;
  }
  .inner_banner_content .brows_banner_left_content .auctions_details ul {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .inner_banner_content .brows_banner_left_content .auctions_details h3 {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 25px;
  }
  .inner_banner_content .brows_banner_left_content .auctions_details h6 {
    font-size: 12px;
  }
  .inner_banner_content
    .brows_banner_left_content
    .auctions_details
    .auction_btn_banner {
    padding: 6px 10px;
    font-size: 12px;
    line-height: 22px;
    display: inline-block;
    background: #000000;
    color: #ffffff;
  }
}

@media (max-width: 375px) {
  .inner_banner_content .brows_banner_left_content .auctions_details ul li {
    font-size: 10px;
  }
}
