.action_calenda_filter_menu {
  margin-top: 80px;
  font-family: "Poppins", sans-serif;
}

.action_calenda_filter_menu .filter_heading {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #19191d;
}
.action_calenda_filter_menu .accordion {
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px 16px;
}
.action_calendar_left_filter .custom_slider_heading_filter select.form-control {
  text-align: center;
}
.action_calenda_filter_menu .accordion .card-header button {
  border-bottom: 1px solid #c4c4c4;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #19191d;
  box-shadow: none;
}

.action_calenda_filter_menu .accordion .card-header button::before {
  content: "";
  background: #212121;
  width: 50%;
  height: 2px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.card-body > .filter_list {
  width: 100% !important;
}

.action_calenda_filter_menu .accordion .filter_list .form-check {
  padding-left: 28px;
}

.action_calenda_filter_menu
  .accordion
  .filter_list
  .form-check
  .form-check-input {
  opacity: 0;
}

.action_calenda_filter_menu
  .accordion
  .filter_list
  .form-check
  .form-check-label {
  position: relative;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
}

.action_calenda_filter_menu
  .accordion
  .filter_list
  .form-check
  .form-check-label::before {
  content: "";
  position: absolute;
  border: 2px solid #c4c4c4;
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  left: -23px;
  top: 3px;
}

.action_calenda_filter_menu
  .accordion
  .filter_list
  .form-check
  .form-check-label::after {
  content: "";
  position: absolute;
  background: url(../../../assets/image/icon-check.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  left: -21px;
  top: 4px;
  transform: scale(0);
}

.action_calenda_filter_menu
  .accordion
  .filter_list
  .form-check
  .form-check-input:checked
  ~ .form-check-label::after {
  transform: scale(1);
}

.action_calenda_filter_menu .accordion .filter_list .form-group {
  margin-bottom: 10px;
}

.action_calenda_filter_menu .accordion .filter_list .show_more_filter_list {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #929292;
  background-color: #fff;
  border: none;
}

.custom_slider_heading_filter .form-group .form-control {
  border: 1px solid rgba(0, 0, 0, 0.3);
  height: 36px;
  font-weight: 300;
  font-size: 14px;
  padding: 0 3px;
  color: #111111;
  outline: 0 !important;
  box-shadow: none;
  background: #ffffff;
  font-family: "Noto Serif", serif;
}

.mobile_right_sidebar_content .select_address_content {
  max-width: 100%;
}

@media (max-width: 991px) {
  .action_calenda_filter_menu {
    margin-top: 0;
  }
  .action_calenda_filter_menu .accordion {
    position: fixed;
    right: -100%;
    top: 0;
    max-height: 100vh;
    height: 100%;
    border-radius: 0;
    overflow-y: auto;
    width: 100%;
    z-index: 9999;
    background: #fff;
    transition: all 0.5s ease-in-out;
  }
  .action_calenda_filter_menu .accordion.active {
    right: 0;
  }
  .action_calenda_filter_menu .accordion .responsive_menu_close a {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
  }
  .mobile_right_sidebar_content {
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    padding: 20px 16px;
  }
}

@media (max-width: 767px) {
  .action_calenda_filter_menu .filter_heading img {
    width: 28px;
  }
}

@media (min-width: 900px) {
  .clearAllStyles {
    cursor: pointer;
    margin-left: 7.8em;
  }
}
