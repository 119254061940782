.browse_listing_inner_content .filter_list_data_column .data_column_box {
  margin-bottom: 50px;
}
.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box
  .lot_number {
  font-weight: 500;
  font-size: 14px;
  padding: 6px 14px;
  color: #333333;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin-bottom: 8px;
  font-family: "Poppins", sans-serif;
}
.lotSoldPriceList span {
  font-weight: bold;
}
.lots_cost_lat_coll_details {
  margin-bottom: 10px;
}
.lot-grid-view-sec-estimate-follow .btn_lots_font_issue {
  display: flex;
  justify-content: space-between;
}
.lot-grid-view-sec-estimate-follow .btn_lots_font_issue .lots_btn_same {
  width: 47%;
  justify-content: center;
}
.lotunSoldPriceList {
  color: rgba(0, 0, 0, 0.5);
}
.lineClampLot {
  min-height: 40px !important;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.price-block {
  max-width: 140px !important;
}

.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box
  .lots_slider_cont_details
  h4 {
  font-size: 16px;
  min-height: 52px;
  margin: 10px 0;
  font-family: "Noto Serif", serif;
}

.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box
  .lots_slider_cont_details
  .lots_cost
  span {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box
  .lots_slider_cont_details
  .lots_cost
  span.strong {
  color: #333333;
  font-weight: 600;
}
.lots_slider_cont_details a,
.lots_slider_cont_details a:hover {
  color: #212529;
  text-decoration: none;
  cursor: pointer;
}
.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box
  .lots_slider_cont_details
  .btn_lots
  .lots_btn_same {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  margin: 2px;
  border-radius: 4px;
  padding: 0 10px;
  font-weight: 500;
  color: #333333;
  border: 1px solid #333333;
  height: 37px;
  vertical-align: top;
  line-height: 37px;
}

.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box
  .lots_slider_cont_details
  .btn_lots
  .lots_btn_same
  img {
  width: 15px;
}

.load_more_list_btn a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background: #333333;
  border-radius: 5px;
  padding: 10px 20px;
}
.no-results-sec-coll-details {
  color: #111;
  text-align: center;
  font-family: Noto Serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 177.778% */
  opacity: 0.4000000059604645;

  width: 50%;
  margin: auto;
  margin-top: 100px;
}
.card-box-top-right-new-cd {
  font-size: 14px !important;
  margin-bottom: 10px !important;
}
@media (max-width: 991px) {
  .browse_listing_inner_content
    .filter_list_data_column
    .data_column_box
    .lots_slider_cont_details
    .btn_lots {
    display: flex;
  }
  .lot-grid-view-sec-estimate-follow .btn_lots_font_issue {
    gap: 20px;
    justify-content: flex-start;
  }

  .no-results-sec-coll-details {
    width: 80%;
  }
}
@media (min-width: 990px) and (max-width: 1370px) {
  .btn_lots_font_issue {
    display: flex;
  }
  .btn_lots_font_issue button {
    font-size: 13px;
    padding: 0px 4px;
  }
  .btn_lots_font_issue a {
    padding: 0px 5px;
    font-size: 12px;
  }
}
.followingThisBid {
  background-color: #333 !important;
  color: #fff !important;
}
