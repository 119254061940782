/* .modal-content {
    min-width: 609px;
    border: none;
    border-radius: unset;
}

.modal-body {
    display        : flex;
    justify-content: center;
    align-items    : center;
    min-height     : 195px;
} */

.modalBodyText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 40px;
  color: #000000;
  margin: 0 !important;
  text-align: center;
}
.modal-the-sub-title-text {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 31px;
  color: #000000;
  margin: 0 !important;
  text-align: center;
  margin-top: 20px !important;
}
.modalCandelBtn {
  width: 154px;
  height: 50px;
  border: 1px solid #000000;
  background: #ffffff;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-right: 16px;
}

.modalCandelBtn:hover,
.modalCandelBtn:focus,
.modalCandelBtn:active {
  background-color: #ffffff !important;
  border: 1px solid #000000 !important;
  color: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.modalDeleteBtn,
.modalCandelBtn {
  width: 148px;
  height: 50px;
  background: #000000 !important;
  border: none;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}
.bidSuccess-popup-sec .modalCandelBtn {
  width: auto;
}
.bidSuccess-popup-sec .modalDeleteBtn,
.bidSuccess-popup-sec .modalDeleteBtn:hover {
  background: #fff !important;
  color: #000 !important;
  border: 1px solid #000 !important;
}
.modalDeleteBtn:hover,
.modalDeleteBtn:focus,
.modalDeleteBtn:active,
.modalCandelBtn:hover,
.modalCandelBtn:focus,
.modalCandelBtn:active {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
  background-color: #000000 !important;
  color: #ffffff !important;
}
.modalBodyTextYThanks {
  margin-top: 20px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 40px;
  color: #000000;
  text-align: center;
}
.modalBodyCompleted {
  text-align: center;
  padding: 43px 52px;
  display: block;
}
.viewResultsBtn {
  margin: auto;
  background-color: #000;
  color: #fff;
  border: 0px;
  border-radius: 2px;
  padding: 15px 35px;
  width: 300px;
  margin-top: 20px;
}

.long-text-message-flow .modalBodyDefault {
  padding: 20px;
}
.long-text-message-flow .modalBodyText {
  font-size: 20px;
}
.long-text-message-flow .modal-footer {
  padding: 10px 30px 30px 30px;
}
.cancel-btn-popup-latest .modalCandelBtn,
.cancel-btn-popup-latest .modalCandelBtn:hover {
  background: #fff !important;
  color: #000 !important;
  border: 1px solid #000 !important;
}
@media (min-width: 767px) {
  .long-text-message-flow .modal-content {
    min-width: 600px !important;
  }
  .long-text-message-flow .modalBodyDefault {
    padding: 40px;
  }
}
@media (max-width: 460px) {
  .bidSuccess-popup-sec button {
    font-size: 14px;
  }
  .modalDeleteBtn,
  .modalCandelBtn {
    width: 100px;
  }
}
