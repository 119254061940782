/* body,
.wrapper {
  overflow-x: hidden;
} */

.container {
  max-width: 1370px;
}

.all_pages_back_btn {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  font-family: "Noto Serif", serif;
}
/* page top bar & hedaer css start */

.page_top_bar {
  background: #111111;
}

.page_top_bar .navbar {
  font-family: "Poppins", sans-serif;
}

.page_top_bar .navbar .navbar-nav .nav-link {
  font-size: 12px;
  padding: 7px 10px;
}

.header-search-input {
  /* min-width: 207px; */
  min-height: 32px;
  transition: all 0.5s ease-in-out;
}

.header-search-input button {
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  outline: 0;
  z-index: 99;
}

.header-search-input input {
  border: 1px solid;
  display: none;
  border-color: rgb(118, 118, 118);
  transition: all 0.5s ease-in-out;
  height: 34px;
  font-size: 13px;
  width: 207px;
  padding: 0 10px;
  border-radius: 2px;
  position: absolute;
  right: 0;
  top: 0;
}

.header-search-input:hover input {
  display: block;
}

.page_header .navbar .header_user_btn {
  margin-left: 18px;
  border-radius: 6px;
  transition: all 0.5s ease-in-out;
  padding: 6px;
}

.page_header .navbar .header_user_btn:hover {
  background: #fff8ef !important;
}

.page_header .navbar {
  padding: 9px 0;
  font-family: "Poppins", sans-serif;
}

.page_header .navbar .navbar-nav .nav-link {
  padding: 5px 10px;
  margin: 0 10px;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 21px;
  color: #555555;
  border-radius: 6px;
  transition: all 0.5s ease-in-out;
}

.page_header .navbar .navbar-nav .nav-link:hover {
  background: #fff8ef;
  color: #111111;
}

/* page top bar & hedaer css end */

/* page footer css start */

.page_footer .footer_link_content {
  padding: 90px 0 40px;
}

.page_footer .footer_logo {
  padding-top: 40px;
}

.page_footer .footer_logo img {
  margin-bottom: 20px;
  width: 57px;
  height: 83px;
  object-fit: fill;
}

.page_footer .footer_logo p {
  font-size: 14px;
  color: #777777;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
}

.page_footer .footer_links_list .footer_links_heading {
  font-weight: 600;
  font-size: 22px;
  color: #111111;
  margin-bottom: 30px;
  font-family: "Noto Serif", serif;
}

.page_footer .footer_links_list .footer_link_nav,
.page_footer .footer_links_list .footer_social_nav {
  list-style: none;
}

.page_footer
  .footer_links_list
  .footer_link_nav
  .footer_link_item
  .footer_link {
  font-weight: 500;
  font-size: 14px;
  color: #555555;
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
  display: inline-block;
}

.page_footer
  .footer_links_list
  .footer_social_nav
  .footer_social_item
  .footer_social_link {
  width: 48px;
  height: 48px;
  border-radius: 2px;
  background: #ebebeb;
  margin: 2px;
}

.page_footer
  .footer_links_list
  .footer_social_nav
  .footer_social_item
  .footer_social_link
  img {
  height: 25px;
}

.page_footer .accordion .footer_links_list .footer_links_heading img {
  transition: all 0.5s ease-in-out;
}

.page_footer .accordion .footer_links_list .footer_links_heading.show img {
  transform: rotate(180deg);
}

.page_footer .footer_newsletter {
  background: #555555;
  padding: 32px 0;
  font-family: "Poppins", sans-serif;
}

.page_footer .footer_newsletter .footer_newletter_text h3 {
  font-weight: 600;
  font-size: 32px;
}

.page_footer .footer_newsletter .footer_newletter_email_input {
  max-width: 411px;
}

.page_footer .footer_newsletter .footer_newletter_email_input input {
  padding: 15px 0;
  font-weight: 500;
  width: calc(100% - 108px);
  outline: 0;
  border-radius: 4px 0 0 4px;
}

.page_footer .footer_newsletter .footer_newletter_email_input button,
.page_footer .footer_newsletter .footer_newletter_email_input button:active {
  background: #111111;
  border-radius: 0px 4px 4px 0px;
  padding: 15px 15px 15px 10px;
  width: 108px;
  font-weight: 500;
}

.footer_newsletter_mobile_app {
  background: #fafafa;
  padding: 41px 0;
}

.footer_newsletter_mobile_app .footer_newsletter_mobile_text h3 {
  font-weight: bold;
  font-size: 32px;
  color: #111111;
}

.footer_newsletter_mobile_app .footer_newsletter_app_img .play_store_img {
  margin-right: 50px;
}

.login_modal.modal .modal-dialog {
  width: 650px;
  max-width: calc(100% - 30px);
  margin: 0 auto;
}

.login_modal .modal-body {
  padding: 15px 15px 40px 50px;
  font-family: "Noto Serif", serif;
}
.login_modal .login-popup-content-sec-body {
  padding-top: 30px;
  padding-right: 30px;
}
.login_modal .modalCloseIcon-login-popup {
  height: 30px;
  width: 30px;
  float: right;
  cursor: pointer;
}
.login_modal .modal-body h3 {
  font-size: 40px;
  font-weight: 700;
  line-height: 54px;
  /* padding-bottom: 24px; */
  color: #000;
}

.login_modal .modal-body .form-label {
  font-size: 14px;
  line-height: 19px;
  color: #111111;
  margin: 0 0 8px;
  font-weight: 500;
}

.login_modal .modal-body .form-control {
  height: 40px;
  font-size: 14px;
  color: #111111;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.login_modal .modal-body .form-text {
  font-size: 10px;
  line-height: 15px;
  font-family: "Poppins", sans-serif;
  color: #9e1616;
  margin: 0px;
  padding: 0px;
  font-size: 0.875em;
  color: #dc3545;
  margin-top: 3px;
}

.login_modal .forgot_psw {
  font-size: 16px;
  line-height: 28px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #111111;
  padding: 4px 0;
  cursor: pointer;
}

.login_keepmelogin_btn {
  padding: 40px 0 54px;
}

.login_keepmelogin_btn .form-check-label {
  position: relative;
}

.login_keepmelogin_btn .form-check-label::before {
  content: "";
  width: 24px;
  height: 24px;
  background: #111111;
  border-radius: 7.2px;
  position: absolute;
  left: -32px;
  top: 0px;
  display: block;
}
.login_keepmelogin_btn .form-check-input {
  opacity: 0;
}

.login_keepmelogin_btn .form-check {
  padding-left: 30px;
}

.login_keepmelogin_btn .form-check-label::after {
  position: absolute;
  left: -27px;
  top: 7px;
  display: block;
  font-family: "FontAwesome";
  content: "";
  font-size: 15px;
  color: #fff;
  opacity: 0;
  width: 15px;
  height: 9px;
  background-image: url(../../assets/image/true_arrow.svg);
}

.login_keepmelogin_btn .form-check-input:checked ~ .form-check-label::after {
  opacity: 1;
}

.login_keepmelogin_btn .login_btn {
  background: #000;
  border-color: #000;
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  padding: 14px 15px 15px;
  font-weight: 900;
  border-radius: 6px;
  min-width: 150px;
}

.login_modal .modal-content .sign_up_text {
  text-align: right;
}

.sign_up_text h5,
.sign_up_text h5 a {
  font-size: 16px;
  line-height: 28px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #111111;
}
.sign_up_text h5 a {
  text-decoration: underline;
}

@media (min-width: 991px) {
  .page_footer {
    margin-top: 100px;
  }
}
@media (max-width: 991px) {
  .page_footer .desktop_footer_link_list {
    display: none;
  }
  .page_footer .accordion {
    display: block !important;
  }
  .page_footer .footer_logo {
    order: 4;
    text-align: center;
  }
  .page_footer .footer_logo img {
    margin: 10px 0;
    /* width: 130px; */
  }
  .page_footer .accordion .footer_links_list .footer_links_heading {
    border-bottom: 1px solid #c4c4c4 !important;
    padding-bottom: 15px !important;
  }
  .page_header .header-search-input {
    order: 2;
    margin-left: auto;
    min-width: unset;
    width: 45px;
  }
  .page_header .header-search-input input {
    position: absolute;
    right: 0;
    top: 100%;
    width: 132px;
    z-index: 999;
  }
  .page_header .navbar .header_user_btn {
    margin-right: 6px;
    margin-left: 0px;
    order: 3;
  }
  .navbar-light .navbar-toggler {
    order: 4;
  }
  .navbar-light .navbar-brand {
    order: 1;
  }
  .navbar-collapse {
    order: 5;
  }
}

/* page footer css end */

/* page top banner slider css start */

.custom_top_banner_slider {
  font-family: "Noto Serif", serif;
  height: 600px;
}

.custom_top_banner_slider .carousel .carousel-control-prev,
.custom_top_banner_slider .carousel .carousel-control-next {
  width: 33px;
  height: 33px;
  background: #fafbfc;
  border: 0.772727px solid rgba(255, 255, 255, 0.3);
  border-radius: 1px;
  opacity: 1;
  left: 127px;
  right: auto;
  top: 75%;
}

.custom_top_banner_slider .carousel .carousel-control-next {
  left: 169px;
}

.custom_top_banner_slider .carousel .carousel-control-prev span,
.custom_top_banner_slider .carousel .carousel-control-next span {
  display: none;
}

.custom_top_banner_slider .carousel .carousel-control-prev::before,
.custom_top_banner_slider .carousel .carousel-control-next::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  background: url(../image/arrow-left.svg) no-repeat;
  width: 20px;
  height: 14px;
  background-size: contain;
  transform: translate(-50%, -50%);
}
.custom_top_banner_slider .carousel .carousel-control-next::before {
  background: url(../image/arrow-right.svg) no-repeat;
}
.font-weight-bold-login-poup {
  text-decoration: underline;
  cursor: pointer;
}
.custom_top_banner_slider .carousel .carousel-caption {
  right: auto;
  left: 127px;
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
  width: 80%;
}

.custom_top_banner_slider .carousel .carousel-caption span {
  font-size: 14px;
}
.footer_error_msg {
  color: red;
  text-align: center;
  margin-top: 10px;
  margin-bottom: -33.5px;
}
.footer_success_text {
  color: #11e411;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.custom_top_banner_slider .carousel .carousel-caption span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 19px;
  background: #fdd490;
  transform: rotate(15deg);
}

.custom_top_banner_slider .carousel .carousel-caption h3 {
  font-size: 60px;
  color: #fff;
  font-size: 60px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.custom_top_banner_slider .carousel-indicators {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  bottom: auto;
  left: auto;
  display: block;
  margin: 0;
}

.custom_top_banner_slider .carousel-indicators li {
  height: 91px;
  width: 64px;
  margin: 0;
  border-top: 0;
  border-bottom: 0;
  background: transparent;
  position: relative;
  text-indent: 0;
}

.custom_top_banner_slider .carousel-indicators li::before {
  content: "";
  position: absolute;
  height: 91px;
  width: 3px;
  background: #fff;
  right: 0;
  top: 0;
}

.custom_top_banner_slider .carousel-indicators li::after {
  content: "01";
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  font-size: 40px;
  color: #fff;
  width: 45px;
  opacity: 0;
}

.custom_top_banner_slider .carousel-indicators li.active::after {
  opacity: 1;
}

.custom_top_banner_slider .carousel-indicators li:nth-child(2)::after {
  content: "02";
}
.custom_top_banner_slider .carousel-indicators li:nth-child(3)::after {
  content: "03";
}
.custom_top_banner_slider .carousel-indicators li:nth-child(4)::after {
  content: "04";
}
.custom_top_banner_slider .carousel-indicators li:nth-child(5)::after {
  content: "05";
}
.custom_top_banner_slider .carousel-indicators li:nth-child(6)::after {
  content: "06";
}

/* page top banner slider css end */

/* slider heading content start */

.custom_slider_heading_filter {
  padding: 56px 0 32px;
}

.custom_slider_heading_filter .custom_slider_heading_filter_content {
  width: 50%;
}
.custom_slider_heading_filter .custom_slider_heading_filter_content label {
  font-weight: 500;
}

.custom_slider_heading_filter
  .custom_slider_heading_filter_content
  .form-control {
  max-width: 108px;
  padding: 9px 10px;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: none;
  background: url(../image/Bottom_arrow.svg) no-repeat top 17px right 15px
    #ffffff;
}

.custom_slider_heading_filter
  .custom_slider_heading_filter_content
  .form-group {
  margin-right: 30px;
}

.custom_slider_heading_filter
  .custom_slider_heading_filter_content
  .view_all_btn {
  min-width: 110px;
  font-family: "Noto Serif", serif;
  color: #333333;
  font-size: 20px;
}

.custom_slider_heading_filter h3 {
  font-family: "Noto Serif", serif;
  font-weight: 600;
  font-size: 30px;
}

.custom_slider_heading_filter h3::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 4px;
  height: 30px;
  background: #fdd490;
  transform: rotate(15deg);
}

.custom_lots_slider_section .custom_slider_heading_filter {
  padding-top: 120px;
}

.custom_lots_slider_section {
  padding-left: 40px;
}

/* slider heading content end */

/* modal css start */

.custom_modal_box .modal-content {
  padding: 30px 50px;
  border-radius: 6px;
  border: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  font-family: "Noto Serif", serif;
}

.custom_modal_box .modal-dialog-centered {
  min-height: 100vh;
}

.custom_modal_box .modal-content .modal-body {
  padding: 0;
}

.custom_modal_box .modal-content .modal-body h3 {
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  color: #111;
  margin-bottom: 34px;
}

.custom_modal_box .modal-content .modal_btns .btn-outline-secondary {
  border-color: #000;
  color: #000;
  background: #000;
  font-size: 16px;
  line-height: 19px;
  padding: 12px 30px 12px;
  font-weight: 600;
  border-radius: 4px;
  min-width: 100px;
}

.custom_modal_box
  .modal-content
  .modal_btns
  .btn-outline-secondary.save_change_modal_btn {
  color: #fff;
}

.custom_modal_box
  .modal-content
  .modal_btns
  .btn-outline-secondary.close_modal_btn {
  background: #fff;
}

.custom_modal_box .modal-content .modal_btns .btn-outline-secondary:hover {
  background: #000;
  color: #fff;
}

.custom_modal_box .modal-content .modal_btns {
  padding-top: 40px;
}
.login-popup-content-sec-body-con {
  display: flex;
  justify-content: space-between;
}
.login_modal .login_logo_sign_in_model {
  height: 70px;
  margin-bottom: 20px;
  padding-right: 60px;
}

/* modal css end */

/* media query css start */

@media (min-width: 999px) {
  .custom_top_banner_slider .carousel .carousel-item {
    height: 600px;
  }
  .custom_top_banner_slider .carousel .carousel-item img {
    max-height: 600px;
  }
}
@media (max-width: 1400px) {
  .custom_top_banner_slider .carousel .carousel-control-prev,
  .custom_top_banner_slider .carousel .carousel-control-next {
    top: 75%;
  }
}

@media (max-width: 1366px) {
  .container {
    max-width: 1250px !important;
  }
}

@media (max-width: 1200px) {
  .custom_top_banner_slider .carousel .carousel-control-prev,
  .custom_top_banner_slider .carousel .carousel-control-next {
    top: 75%;
  }
  .custom_top_banner_slider .carousel-indicators li,
  .custom_top_banner_slider .carousel-indicators li::before {
    height: 70px;
  }
  .custom_top_banner_slider .carousel-indicators li::after {
    right: 12px;
    font-size: 35px;
  }
}

@media (max-width: 1171px) {
  .container {
    max-width: 98%;
  }
}

@media (max-width: 991px) {
  .footer_error_msg {
    margin-bottom: 0px;
    text-align: center;
  }
  .custom_top_banner_slider .carousel .carousel-control-prev,
  .custom_top_banner_slider .carousel .carousel-control-next {
    display: none;
  }
  .custom_top_banner_slider .carousel-indicators li::before,
  .custom_top_banner_slider .carousel-indicators li::after {
    display: none;
  }
  .custom_top_banner_slider .carousel-indicators li {
    width: 16px;
    height: 6px;
    border-radius: 6px;
    background: rgba(182, 182, 182, 0.3);
    margin: 0 6px;
  }
  .custom_top_banner_slider .carousel-indicators li.active {
    background: #fff;
  }
  .custom_top_banner_slider .carousel-indicators {
    display: flex;
    right: 0;
    bottom: 0;
    left: 0;
    margin-right: 15%;
    margin-left: 15%;
    top: auto;
    transform: translateY(0);
    margin-bottom: 12px;
  }
  .custom_top_banner_slider .carousel .carousel-caption {
    right: 15%;
    bottom: 20px;
    left: 15%;
    top: auto;
    transform: translateY(0%);
  }
  .custom_top_banner_slider .carousel .carousel-caption span {
    font-size: 12px;
  }
  .custom_top_banner_slider .carousel .carousel-caption span::before {
    height: 14px;
  }
  .custom_top_banner_slider .carousel .carousel-caption h3 {
    font-size: 46px;
  }
  .custom_top_banner_slider .carousel .carousel-caption p {
    font-size: 14px;
  }
  .page_footer .footer_newsletter {
    padding: 16px 0;
  }
  .custom_slider_heading_filter .custom_slider_heading_filter_content {
    width: 33%;
  }
  .custom_slider_heading_filter
    .custom_slider_heading_filter_content
    .view_all_btn {
    text-align: right;
  }
  .navbar-collapse {
    position: absolute;
    left: calc(100% + 30px);
    top: 69px;
    z-index: 999;
    background: #fff;
    width: calc(100% + 50px);
    height: 0;
    transition: all 0.3s ease-in-out;
    padding: 0 10px;
    min-height: 100vh;
    display: none !important;
  }
  .navbar-collapse.show {
    left: -21px;
    display: block !important;
    transition: all 0.5s ease-in;
  }
  .navbar-collapse .navbar-nav {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding-bottom: 14px;
  }
  .page_header .navbar .navbar-nav .nav-link {
    margin: 0;
    padding: 10px 0;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url(../image/close.svg);
  }
  .navbar-light .navbar-toggler.collapsed .navbar-toggler-icon {
    background-image: url(../image/menu.svg);
  }
}

@media (max-width: 767px) {
  .page_footer .footer_newsletter .footer_newletter_text h3,
  .footer_newsletter_mobile_app .footer_newsletter_mobile_text h3 {
    font-size: 25px;
  }
  .page_footer .footer_newsletter .footer_newletter_text p {
    font-size: 14px;
  }
  .custom_slider_heading_filter h3 {
    font-size: 23px;
  }
  .custom_slider_heading_filter
    .custom_slider_heading_filter_content
    .view_all_btn {
    font-size: 17px;
  }
  .custom_modal_box .modal-content {
    padding: 16px;
  }
  .custom_modal_box .modal-content .modal-body h3 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .custom_modal_box .modal-content .modal_btns {
    padding-top: 15px;
  }
  .custom_modal_box .modal-content .modal_btns .btn-outline-secondary {
    font-size: 14px;
    line-height: 14px;
    padding: 12px 15px 12px;
  }
  .account_all_right_content_heading {
    padding-bottom: 16px !important;
  }
  .login_modal.modal .modal-dialog {
    width: 100%;
    min-height: 100vh;
    max-width: 100%;
  }
  .login_modal .modal-content {
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100vh;
  }
  .login_modal .modal-content .login_logo {
    margin-bottom: 0px;
  }

  .login_modal .modal-content .sign_in_continue_text {
    font-size: 12px;
    line-height: 19px;
    color: #5b5b5b;
    margin-bottom: 24px;
  }
  .login_modal .modal-body h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    padding: 0;
    margin-bottom: 4px;
  }
  .login_modal .modal-content .forgot_psw_text {
    text-align: right;
  }
  .login_modal .modal-content .forgot_psw_text a {
    font-size: 14px;
    line-height: 19px;
    color: #5b5b5b;
    font-family: "Noto Serif", serif;
  }
  .login_modal .modal-content .login_keepmelogin_btn {
    padding: 32px 15px;
  }
  .login_modal .modal-content .login_keepmelogin_btn .login_btn {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 11px 15px;
  }
  .login_modal .modal-content .sign_up_text {
    text-align: center;
  }
  .login_modal .modal-content .sign_up_text h5,
  .login_modal .modal-content .sign_up_text h5 a {
    font-family: "Noto Serif", serif;
    color: #5b5b5b;
    font-size: 14px;
  }
  .page_footer .footer_links_list .footer_links_heading {
    font-size: 16px;
  }
  .page_footer
    .footer_links_list
    .footer_social_nav
    .footer_social_item
    .footer_social_link {
    margin-right: 24px;
  }
  .page_footer
    .footer_links_list
    .footer_social_nav
    .footer_social_item:last-child
    .footer_social_link {
    margin-right: 2px;
  }
  .page_footer .footer_newsletter .footer_newletter_text h3,
  .footer_newsletter_mobile_app .footer_newsletter_mobile_text h3 {
    font-size: 16px;
  }
  .footer_newsletter_mobile_app .footer_newsletter_app_img .play_store_img img {
    width: 114px;
  }
  .footer_newsletter_mobile_app .footer_newsletter_app_img .app_store_img img {
    width: 97px;
  }
  .footer_newsletter_mobile_app {
    padding: 16px 0 24px;
  }
  .page_footer .footer_link_content {
    padding: 16px 0 24px;
  }
  .header-top-box-content a {
    margin-left: 1px;
  }
  .header-top-box-content {
    width: 90%;
  }
  .header-top-box-content ul {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    width: 100%;
    padding: 0px;
  }
  .footer_error_msg {
    margin-bottom: 0px;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .custom_top_banner_slider .carousel .carousel-item img {
    min-height: 300px;
    object-fit: cover;
  }
  .custom_top_banner_slider .carousel .carousel-caption span {
    font-size: 10px;
  }
  .custom_top_banner_slider .carousel .carousel-caption h3 {
    font-size: 24px;
  }
  .custom_top_banner_slider .carousel .carousel-caption p {
    font-size: 12px;
  }
  .custom_top_banner_slider .carousel .carousel-caption {
    right: 0;
    left: 24px;
  }
  .custom_slider_heading_filter h3::before {
    height: 22px;
  }
  .custom_slider_heading_filter h3 {
    font-size: 18px;
  }
  .custom_slider_heading_filter
    .custom_slider_heading_filter_content
    .view_all_btn {
    font-size: 12px;
  }
  .login_modal .modal-body {
    padding: 30px 15px;
  }
}

@media (max-width: 375px) {
  .container {
    max-width: 100%;
  }
}
/* media query for top banner */
@media (max-width: 768px) {
  .custom_top_banner_slider {
    height: 300px;
  }
  .custom_action_slider_section {
    padding-top: 24px;
  }
  /* .custom_slider_heading_filter {
        padding: 0px 0px 16px !important;
    } */
}
/* media query css end */

@media (min-width: 989px) and (max-width: 1200px) {
  .custom_top_banner_slider .carousel .carousel-caption {
    right: auto;
    left: 127px;
    bottom: auto;
    top: 35%;
  }
  .custom_top_banner_slider .carousel .carousel-control-prev,
  .custom_top_banner_slider .carousel .carousel-control-next {
    top: 60%;
  }
}
