.paymentConfirmContainer {
  margin: 24px 48px 136px 50px !important;
}

.paymentConfirmTitle {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  color: #000000;
}

.paymentStatusMsg {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  padding-top: 24px;
  padding-bottom: 30px;
}

.paymentViewOrder {
  background: #111111;
  border: 1px solid #111111;
  border-radius: 4px;
  width: 203px;
  height: 46px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-right: 38px;
  margin-left: 15px;
}

.paymentViewOrder:hover,
.paymentViewOrder:focus,
.paymentViewOrder:active {
  color: #ffffff !important;
}

.paymentDownloadInvoice {
  border: 1px solid #111111;
  border-radius: 4px;
  width: 203px;
  height: 46px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.paymentConfirmContainer .spinner-border {
  margin-left: 50% !important;
  min-height: 500px;
}
