.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box_list_view
  .lots_slider_img {
  width: 300px;
}

.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box_list_view
  .lots_slider_cont_details {
  width: calc(100% - 300px);
  position: relative;
  padding-left: 20px;
}

.browse_listing_inner_content
  .filter_list_data_column
  .data_column_box_list_view
  .lots_slider_cont_details
  h4 {
  font-size: 20px;
  font-weight: 600;
  min-height: initial;
  margin-bottom: 10px;
}

.data_column_box_list_view .btn_lots .lots_btn_same {
  width: 137px;
}

.data_column_box_list_view .price_list_btn {
  height: calc(100% - 34px);
}

.price-block {
  max-width: 140px !important;
}

.onHover:focus,
.onHover:active {
  background: #333 !important;
  border: none !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 8%) inset !important;
}

.fullWidth {
  min-width: 100%;
}
.lotCloseContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}
.estimateContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}
.textSize {
  font-size: 14px !important;
}

.priceLine {
  display: flex;
  /* justify-content: space-between; */
}
.no-results-sec-coll-details {
  color: #111;
  text-align: center;
  font-family: Noto Serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 177.778% */
  opacity: 0.4000000059604645;

  width: 50%;
  margin: auto;
  margin-top: 100px;
}

@media (max-width: 767px) {
  .browse_listing_inner_content
    .filter_list_data_column
    .data_column_box_list_view
    .lots_slider_img {
    width: 250px;
  }
  .browse_listing_inner_content
    .filter_list_data_column
    .data_column_box_list_view
    .lots_slider_cont_details
    h4 {
    font-size: 13px;
  }
  .browse_listing_inner_content
    .filter_list_data_column
    .data_column_box_list_view
    .lots_slider_cont_details {
    width: calc(100% - 250px);
  }
  .no-results-sec-coll-details {
    width: 80%;
  }
}

@media (max-width: 568px) {
  .data_column_box_list_view .price_list_btn {
    height: auto;
  }
  .data_column_box_list_view .price_list_btn .lots_cost {
    margin-bottom: 10px;
  }
  .browse_listing_inner_content
    .filter_list_data_column
    .data_column_box_list_view
    .lots_slider_img {
    width: 130px;
  }
  .browse_listing_inner_content
    .filter_list_data_column
    .data_column_box_list_view
    .lots_slider_cont_details {
    width: calc(100% - 130px);
    padding-left: 10px;
  }
  .browse_listing_inner_content
    .filter_list_data_column
    .data_column_box
    .lots_slider_cont_details
    .lots_cost
    span {
    font-size: 11px;
  }
  .browse_listing_inner_content
    .filter_list_data_column
    .data_column_box
    .lots_slider_cont_details
    .btn_lots
    .lots_btn_same {
    width: 67px;
    font-size: 10px;
    padding: 0px 17px;
    height: 30px;
    text-align: center;
    line-height: 30px;
  }
  .browse_listing_inner_content
    .filter_list_data_column
    .data_column_box
    .lots_slider_cont_details
    .btn_lots_font_issue
    .lots_btn_same {
    width: 90px;
    justify-content: center;
  }
  .browse_listing_inner_content
    .lot-grid-view-sec-estimate-follow
    .btn_lots_font_issue
    .lots_btn_same {
    width: 135px !important;
    font-size: 12px !important;
  }
}
