.action_calendar_page_section {
  padding: 50px 0;
}

.noResults {
  padding: 2px 4px 5px 62px;
  font-size: 20px;
}
.noResults-auctionpafw {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 400px;
}
@media (max-width: 1366px) {
  .action_calendar_page_content .container {
    width: 1140px;
  }
}

@media (max-width: 1171px) {
  .action_calendar_page_content .container {
    width: 98%;
  }
}

@media (max-width: 767px) {
  .action_calendar_page_section {
    padding: 30px 0;
  }
  .noResults-auctionpafw {
    text-align: center;

    padding: 0;
    width: 80%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 200px;
  }
}

@media (max-width: 375px) {
  .action_calendar_page_content .container {
    width: 100%;
  }
}
