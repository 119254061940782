.pri-poli-header-con {
  color: #000;
  font-size: 24px;
  font-family: Noto Serif;
  font-weight: 700;
  line-height: 44px;
}
.pri-poli-header-con-sec {
  color: #000;
  font-size: 18px;
  font-family: Noto Serif;
  line-height: 40px;
}
.pri-poli-header-con-sec span {
  font-weight: bold;
}
.shipping-box-header .pri-poli-header-con-sec-page p {
  margin-bottom: 5px;
}
.shipping-box-header .pri-poli-header-con-sec-page p span {
  font-weight: bold;
  margin-top: 25px;
  display: block;
}
.shipping-box-header .address-privacy-poli {
  line-height: 30px;
  margin-top: 30px;
  margin-bottom: 30px !important;
}
.terms-and-con-rules-sec p span {
  text-decoration: underline;
}
.terms-con-add-space p div {
  height: 20px;
}
