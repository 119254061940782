.purchas_list .lots_slider_follow_btn {
  font-weight: 500;
  font-size: 12px;
  background: #000;
  border-color: #000;
  color: #fff;
  margin-right: 2px;
  border-radius: 4px;
  padding: 8px 5px;
}

.purchas_list
  .more_from
  .trending_lots_slider_cont
  .lots_slider_cont_details
  .btn_lots
  .lots_btn_same {
  padding: 0 5px;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
}

.btn_lots .btn-primary {
  background-color: #000000;
  box-shadow: unset !important;
}

.btn_lots .btn-primary:not(:disabled):not(.disabled).active,
.btn_lots .btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #000000;
  border-color: #000;
}

.purchas_list
  .more_from
  .trending_lots_slider_cont
  .lots_slider_cont_details
  h4 {
  font-size: 13px;
  line-height: 18px;
}

.purchas_list
  .more_from
  .trending_lots_slider_cont
  .lots_slider_cont_details
  .lots_cost
  span {
  font-size: 10px;
}
.lots_slider_followFollow_btn {
  color: #000 !important;
  border: 1px solid #000 !important;
  background-color: #fff !important;
}
.PurchaseImgStyles {
  width: 275px !important;
  height: 273px !important;
}

.ErrorMsg {
  padding-left: 25px;
}
.lots_slider_follow_btn_my_lots {
  cursor: auto !important;
}
