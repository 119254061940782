.custom_top_banner_slider .carousel .carousel-caption .carosel-cat-name {
  color: #fff;
  font-size: 18px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.carosel-sub-sub-text {
  color: #fff;
  font-size: 22px;
  font-family: Noto Serif;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
}
.carosel-btn-sec button {
  border-radius: 4px;
  border: none;
  background: #fff;
  color: #000;
  padding: 16px 37px;
  cursor: pointer;
  color: #000;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  letter-spacing: 0.32px;
  border: 1px solid #000;
}
@media (min-width: 900px) {
  .heroLineClamp {
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
  .heroHoverText {
    color: #000 !important;
    background: #fff;
    width: 50%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin: 0 !important;
    top: -10px;
    padding: 3px 4px 0px 0px !important;
    z-index: 999;
    border-radius: 3px;
  }
}
