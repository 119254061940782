.itempurchaseContainer {
  margin-right: 48px !important;
  margin-left: 50px !important;
  margin-bottom: 136px !important;
}

.paymentOrderTitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}
.rowSelectedAddress {
  float: left;
}
.error_message {
  margin-top: 20px;
  color: red;
}
.user-add-address-btn-sec {
  margin-bottom: 30px;
}
.total-itmes-list-row-total {
  border-top: 3px dotted rgba(0, 0, 0, 0.34);
  padding-top: 10px;
}
.paymentOrderSummary {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
}

.pdHeadingStyles {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
  align-self: flex-start;
  margin-top: 20px;
  padding-top: 26px !important;
  position: absolute !important;
}

.pdStyles {
  margin-right: 48px;
  /* margin-left : 60px; */
  align-self: flex-end;
  padding-top: 24px !important;
}

.ShippingStyles {
}

.noPadding {
  padding: 0 !important;
}

.ppdRow {
  flex-direction: column !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-top: 24px !important;
}

.paymentTitleField {
  width: 89px !important;
  /* opacity: 0.3; */
  /* border    : 1px solid #ced4da; */
}
.paymentTitleField:focus,
.paymentTitleField:active {
  border: 1px solid #111111 !important;
}
.itempurchaseContainer .form-control:focus {
  box-shadow: unset !important;
  border: 1px solid #ced4da !important;
}

/* .pdStyles>.pForm>.form-control {
    width        : 276px;
    padding-right: 24px;
} */

.pForm > .form-label {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #111111;
}

.pFormRow {
  display: flex;
  margin-bottom: 24px;
}

.paymentForm > .form-control {
  width: 335px;
}
.Text_paymentError {
  color: red;
  padding-left: 17px;
  margin: 20px 0px;
}
.smallInputBox {
  width: 150px;
}
.spinner_Loading_iconRow {
  justify-content: center;
}
.smallInputBoxSmall {
  width: 75px !important;
}
.inputBoxNames {
  display: flex;
}
.inputBoxNames > input:nth-child(2) {
  margin-left: 10px;
}

.shippingDetails {
  width: 100%;
  display: block;
}

.paymentAddressStyles {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
}

.paymentFormRadio {
  color: rgba(17, 17, 17, 0.5);
}
.item-purchase-add-new-info {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: block;
  width: 100%;
}
.item-purchase-add-new-info .title-sec-add-address {
  border-top: 1px solid #d9d9d9;
}
.addNewAddressBtn {
  width: 100%;
  height: 50px;
  left: 300px;
  top: 731px;
  /* border: 1px solid #d9d9d9 !important; */
  color: #000000 !important;
  box-sizing: border-box;
  /* border-radius: 4px; */
  /* background: #fff !important; */
  /* margin-left: 256px;
  margin-top: 24px; */
  /* border: 1px solid #d9d9d9; */
  /* border-radius: 4px; */
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  text-align: left;
  letter-spacing: 0.5px;
}
.noBoxShadow:focus,
.noBoxShadow:active {
  box-shadow: unset !important;
}

.orderSummaryStyles {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.04), 4px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin: 24px 0 0 0 !important;
  padding: 24px;
}

.paymentItemImage {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: contain;
  border: none;
  background: #fff;
}

.itemTitleAmount {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  color: #000000;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
}

.paymentItemTitle {
  font-family: Poppins;
  font-size: 16px;
  line-height: 22px;
}

.yourBidAmount {
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
}

.yourBidTitle {
  color: rgba(0, 0, 0, 0.5);
}

.detailsRow {
  justify-content: space-between;
  width: 100%;
}
.total-itmes-list {
}
.total-itmes-list-row {
  justify-content: space-between;
  display: flex;
  gap: 20px;
}
.namesColumn {
  display: flex;
  /* text-align: right; */
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
}

.valuesColumn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-right: 0px !important;
}

.totalPayableAmt {
  font-weight: 600;
}

.orderSummaryHR {
  margin-top: 24px;
  margin-bottom: 24px;
}

.saveCardCheckbox {
  margin-left: 15px;
}

.savedCardsList > .form-check-inline {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #111111;
  padding-bottom: 24px !important;
}

.savedCardsList {
  display: flex;
  flex-direction: column;
}

.useAnotherCardBtn {
  border: 1px solid rgba(17, 17, 17, 0.7) !important;
  box-sizing: border-box;
  border-radius: 4px;
  width: 261px;
  height: 44px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 19px;
  color: rgba(17, 17, 17, 0.7);
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.payment-details-terms-sec {
  margin: auto;
  margin-bottom: 50px;
}
.purchase-lot-row-img img {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  object-fit: contain;
  border: none;
  background: #fff;
}
.payment-details-terms-sec p {
  color: #222;
  font-family: Noto Serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.payment-details-terms-sec p a {
  text-decoration-line: underline;
  color: #222;
}
.warning_text {
  color: red;
  margin-left: 33%;
}
.payNowBtnText {
  justify-content: center;
}

.payNowBtnText button {
  height: 50px;
  background-color: #000000 !important;
  color: #ffffff !important;
  border: none !important;
}

.addAddressBtn {
  width: 150px;
  height: 43px;
  background: #000000;
  border: 1px solid #111111;
  border-radius: 4px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  border: 1px solid !important;
  color: #ffffff;
}
.addAddressBtn:hover,
.addAddressBtn:focus {
  box-shadow: 0px 1px 1px rgb(0 0 0 / 8%) inset !important;
  color: #ffffff;
}
.emptyPhoneNumberField {
  border-color: red !important;
}
.emptyLabelITem {
  margin-top: 18px !important;
}
.twoBoxItem {
  margin-left: -6.9em !important;
}
.phoneNumberStylesItem {
  width: 171px !important;
}
.moveLeft {
  margin-left: -8px !important;
}

.box-header-sec {
  /* height: 80px; */
  margin-top: 25px;
  display: flex;
  background: #f9f9f9;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.box-header-sec span {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  color: #000000;
}
.box-header-sec button {
  border: 1px solid #000;
  background: no-repeat;
  border-radius: 4px;
  height: 45px;
  width: 130px;
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
.left-side-box-content {
  padding-right: 30px !important;
}
.box-header-sec {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.09);
  margin-top: 25px;
}
.box-content-sec {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.09);
  margin-top: 25px;
}
.user-details-sec-info input:disabled,
.user-details-sec-info select:disabled {
  background: #e6e6e6;
  opacity: 0.3;
  border: 1px solid #111111;
  border-radius: 2px;
}

.user-details-sec-info input,
.user-details-sec-info select {
  font-family: "Noto Serif";
}
.continue-shipping-details-sec {
  margin: 20px;
  text-align: right;
}
.continue-shipping-details-sec button {
  background: #111111;
  border: 1px solid #111111;
  border-radius: 4px;
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  height: 46px;
  padding: 0 40px;
  color: #ffffff;
}
.delivery-here-add-address:disabled {
  opacity: 0.6;
}
.box-content-sec-shipping {
  padding: 30px;
  padding-top: 0px;
}
.box-content-sec-payment {
  padding: 30px;
}
.box-content-sec-payment .main-pay-now-btn {
  width: 65%;
  margin: auto;
}
.box-content-sec-shipping .pdStyles.shippingDetails {
  padding: 0px !important;
}
.row-address-list-single-row {
  padding: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-bottom: 20px;
  width: 100%;
  cursor: pointer;
}
.ship-to-me-inperson-sec {
  margin-top: 30px;
  display: flex;
}
.ship-to-me-inperson-sec > div {
  width: 50%;
  color: #171a23;
  font-family: Noto Serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid rgba(32, 32, 32, 0.1);
  background: #fff;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  opacity: 0.5;
}
.ship-to-me-inperson-sec > div.selected {
  border-bottom: 2px solid #000;
  opacity: 1;
}
.ship-to-me-sec {
}
.in-person-sec-sec {
}
.item-purchase-lot-details-row {
  margin-top: 18px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  background: #fff;

  /* padding: 16px; */
}
.purchase-lot-row-top {
  background: rgba(32, 32, 32, 0.05);
  padding: 15px;
  display: flex;
  justify-content: space-between;
}
.purchase-lot-row-top span {
  color: #717171;
  text-align: right;
  font-family: Noto Serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}
.purchase-lot-row-bottom {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.purchase-lot-row-bottom-left {
  display: flex;
  /* justify-content: space-between; */
  gap: 12px;
  width: 50%;
}
.purchase-lot-row-bottom-right {
  width: 50%;
}
.purchase-lot-row-title-con p span {
  border-radius: 4px;
  background: #ffe9e9;
  padding: 4px 8px;
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.purchase-lot-row-title-con p {
  margin-bottom: 12px;
}
.item-pur-lot-title-con {
  color: #000;
  font-family: Noto Serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.item-status-completed-sec {
  margin-top: 30px;
}
.item-status-sec-p {
  margin-top: 10px;
}
.in-person-sec-sec-content {
  color: #222;
  font-family: Noto Serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.5px;
  padding: 90px 150px;
  text-align: center;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  /* text-decoration-line: underline; */
}
.in-person-sec-sec-content a {
  text-decoration-line: underline;
  /* color: #222; */
  font-family: Noto Serif;
  font-size: 16px;
}
#rswp-card-button {
  background-color: #111111 !important;
}
.address-row-name-info span,
.address-row-name-info label {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #171a23;
}
.address-row-address-info {
  margin-top: 8px;
}
.address-row-address-info span {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: #222222;
}
.error_field_sec_item_P input,
.error_field_sec_item_P select {
  border-color: red;
}
.ach-bank-btn {
  margin-top: 30px;
}
.card-input-wrapper input {
  text-transform: capitalize;
}
.main-pay-now-btn .sq-card-message {
  text-transform: capitalize;
}
.text-bold-item-purchase .namesColumn {
  font-weight: 600;
}
.ach-or-button {
  margin-bottom: 30px;
  text-align: center;
}
.ach-bank-btn button {
  text-transform: uppercase;
}
.orderSummaryStyles .valuesColumn span {
  display: block;
  width: max-content;
}
@media (max-width: 770px) {
  .itempurchaseContainer {
    margin-right: 18px !important;
    margin-left: 20px !important;
  }
  .left-side-box-content {
    padding-right: 0px !important;
  }
  .user-details-sec-info .pForm {
    margin-top: 10px;
  }
  .user-details-sec-info .signup-flow-phone-pe {
    margin-top: 10px;
  }
  .continue-shipping-details-sec button {
    font-size: 14px;
  }
  .box-content-sec-shipping {
    padding: 10px;
  }
  .row-address-list-single-row {
    padding: 10px;
  }
  .add-new-addess-sec-con-item .noPadding {
    display: block;
  }
  .box-content-sec-shipping .add-new-addess-sec-con-item {
    padding-right: 5px;
    padding-left: 5px;
  }
  .box-content-sec-payment {
    padding: 10px;
  }
  .box-content-sec-payment .main-pay-now-btn {
    width: 100%;
  }
  .purchase-lot-row-bottom {
    display: block;
  }
  .purchase-lot-row-bottom-left {
    display: block;
    width: 100%;
  }
  .purchase-lot-row-img img {
    width: 80%;
  }
  .purchase-lot-row-title-con {
    margin-top: 15px;
  }
  .purchase-lot-row-bottom-right {
    width: 100%;
  }
  .in-person-sec-sec-content {
    padding: 50px;
  }
  .orderSummaryStyles {
    padding: 15px;
  }
}
