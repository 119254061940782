.loadMore {
  margin-top: 40px;
}
.dateLeft {
  margin-left: -42px;
  margin-right: -44px;
}
.mt-auto-top {
  margin-top: 25px;
}
@media (max-width: 767px) {
  .forMobileViewstyle {
    margin-top: -20px !important;
  }
}
