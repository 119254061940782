.custom_magage_profile_modal .modal-dialog {
  max-width: 650px;
}

.custom_magage_profile_modal .modal-content .form-control {
  height: 40px;
  font-size: 14px;
  color: #111111;
  border-radius: 2px;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
}

.custom_magage_profile_modal .modal-content .form-group {
  margin-bottom: 24px;
}
.profile_page_right_content .manage-profile-btn-sec:hover {
  border: 1px solid #000 !important;
}

.custom_magage_profile_modal .modal-content form .form-group .form-label {
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: #111111;
  margin: 0 0 8px;
  font-weight: 500;
}

.custom_modal_box .modal-content .modal-body h5 {
  padding-bottom: 30px;
  font-size: 20px;
  line-height: 40px;
  color: #000000;
  border-bottom: 1px solid #e9ecef;
}

.emptyLabel {
  margin-top: 27px !important;
}
.twoBox {
  margin-left: -79px !important;
}
.phoneCodeSelect {
  width: 67px !important;
  padding: 0 !important;
}
.phoneNumberStyles {
  width: 183px !important;
}

@media (max-width: 767px) {
  .custom_magage_profile_modal .modal-content .form-control {
    border-radius: 4px;
  }
  .custom_magage_profile_modal .modal-content .form-group {
    margin-bottom: 20px;
  }
  .mobile-number-col {
    width: 140px;
  }
  .mobile-number-col-sec {
    width: calc(100% - 140px);
  }
  .twoBox {
    margin-left: -74px;
  }
}
