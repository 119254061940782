.confirm_msg_text p {
  font-size: 20px;
  line-height: 40px;
  color: #000000;
}

.confirm_msg_text p a {
  color: #000000;
}

@media (max-width: 767px) {
  .confirm_msg_text p {
    font-size: 17px;
    line-height: 25px;
  }
  .confirm_msg_text.custom_modal_box
    .modal-content
    .modal_btns
    .btn-outline-secondary {
    min-width: 70px;
  }
  .confirm_msg_text
    .modal-content
    .modal_btns
    .btn-outline-secondary.close_modal_btn {
    margin-right: 10px !important;
  }
  .confirm_msg_text .modal-content .modal-footer {
    padding: 16px 0 0;
  }
}
