.brows_list_content_page .container {
  width: 1370px;
}

.brows_list_content_page {
  padding-bottom: 40px;
}
.lots_slider_img,
.list_view_img,
.lots_slider_img a {
  width: 100%;
  height: 300px;
  display: flex;
  flex-wrap: wrap;
}
.filter_list_sort_section .custom_select_dropdown select {
  -webkit-appearance: none;
}
.lots_slider_img img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.modalDeleteBtn {
  border: none !important;
}

@media (max-width: 1366px) {
  .brows_list_content_page .container {
    width: 1250px;
  }
}

@media (max-width: 1171px) {
  .brows_list_content_page .container {
    width: 98%;
  }
}

@media (max-width: 767px) {
  .lots_slider_img,
  .list_view_img {
    width: unset;
    height: unset;
  }
  .lots_slider_img,
  .list_view_img,
  .lots_slider_img a {
    width: 100% !important;
  }
}

@media (max-width: 375px) {
  .brows_list_content_page .container {
    width: 100%;
  }
}
