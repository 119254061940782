.action_calendar_right_tab {
  font-family: "Poppins", sans-serif;
}

.action_calendar_right_tab .nav-tabs .nav-item {
  border: 0;
  margin-bottom: 0;
  position: relative;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding: 9px 0;
  display: block;
  color: #333333;
  margin: 0 28px;
}

.action_calendar_right_tab .nav-tabs .nav-item.active {
  color: #333333;
}

.action_calendar_right_tab .nav-tabs .nav-item:first-child {
  margin-left: 0;
}
.action_calendar_right_tab .nav-tabs .nav-item:last-child {
  margin-right: 0;
}

.action_calendar_right_tab .nav-tabs .nav-item.active::before {
  content: "";
  display: block;
  height: 4px;
  width: 100%;
  background: #000;
  position: absolute;
  left: 0px;
  bottom: -1px;
}

.action_calendar_right_tab .nav-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.action_calendar_right_tab .action_tab_content {
  padding-top: 30px;
}

.action_calendar_right_tab .action_tab_content h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}

.action_calendar_right_tab
  .action_tab_content
  .filter_list_data_column
  .data_column_box {
  padding-left: 68px;
  padding-top: 40px;
  padding-bottom: 12px;
}

.action_calendar_right_tab
  .action_tab_content
  .filter_list_data_column
  .data_column_box::before {
  content: "";
  display: block;
  position: absolute;
  left: 22px;
  border-left: 1px solid rgba(0, 0, 0, 0.4);
  width: 1px;
  height: 100%;
  top: 0px;
}

.action_calendar_right_tab
  .action_tab_content
  .filter_list_data_column
  .data_column_box:last-child::after {
  content: "";
  display: block;
  position: absolute;
  left: 22px;
  margin-left: 0;
  border-left: 1px dashed rgba(0, 0, 0, 0.4);
  width: 1px;
  height: 36px;
  bottom: 0px;
  background: #fff;
}

.action_calendar_right_tab
  .action_tab_content
  .filter_list_data_column
  .data_column_box:last-child {
  padding-bottom: 38px;
  margin-bottom: 4px;
}

.action_calendar_right_tab
  .action_tab_content
  .filter_list_data_column
  .data_column_box
  .date-wrap {
  left: 0px;
  top: 0px;
  width: 52px;
  z-index: 2;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 36px 0;
  color: #4e4e4e;
}

.action_calendar_right_tab
  .action_tab_content
  .filter_list_data_column
  .data_column_box
  .date-wrap::before,
.action_calendar_right_tab
  .action_tab_content
  .filter_list_data_column
  .data_column_box
  .date-wrap::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -4px;
  border-left: 1px dashed rgba(0, 0, 0, 0.4);
  width: 1px;
  height: 30px;
  top: 0px;
}

.action_calendar_right_tab
  .action_tab_content
  .filter_list_data_column
  .data_column_box
  .date-wrap::after {
  bottom: 0px;
  top: auto;
}

.action_calendar_right_tab
  .action_tab_content
  .filter_list_data_column
  .data_column_box
  .lots_slider_cont_details
  h6 {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 10px;
}

.action_calendar_right_tab
  .action_tab_content
  .filter_list_data_column
  .data_column_box
  .lots_slider_cont_details
  h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  font-family: "Noto Serif", serif;
}

.action_calendar_right_tab
  .action_tab_content
  .filter_list_data_column
  .data_column_box
  .lots_slider_cont_details
  .border-list
  li {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  padding-right: 12px;
  color: #000;
  position: relative;
}

.action_calendar_right_tab
  .action_tab_content
  .filter_list_data_column
  .data_column_box
  .lots_slider_cont_details
  .border-list
  li::before {
  position: absolute;
  right: 4px;
  top: 50%;
  width: 1px;
  height: 12px;
  margin-top: -7px;
  content: "";
  display: inline-block;
  background: #000;
}

.action_calendar_right_tab
  .action_tab_content
  .filter_list_data_column
  .data_column_box
  .lots_slider_cont_details
  .border-list
  li:nth-child(2)::before {
  display: none;
}

.action_calendar_right_tab
  .action_tab_content
  .filter_list_data_column
  .data_column_box
  .lots_slider_cont_details
  .browse_btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  width: 210px;
  max-width: 100%;
  padding: 7px 8px;
  background: #000;
  border-color: #000;
  border-radius: 4px;
}

.action_calendar_right_tab .list_view_img {
  /* background: #EDEDED; */
}

@media (max-width: 767px) {
  .action_calendar_right_tab
    .action_tab_content
    .filter_list_data_column
    .data_column_box::before,
  .action_calendar_right_tab
    .action_tab_content
    .filter_list_data_column
    .data_column_box
    .date-wrap::before,
  .action_calendar_right_tab
    .action_tab_content
    .filter_list_data_column
    .data_column_box
    .date-wrap::after,
  .action_calendar_right_tab
    .action_tab_content
    .filter_list_data_column
    .data_column_box
    .lots_slider_cont_details
    .border-list
    li:last-child::before,
  .action_calendar_right_tab
    .action_tab_content
    .filter_list_data_column
    .data_column_box:last-child::after {
    display: none;
  }
  .action_calendar_right_tab
    .action_tab_content
    .filter_list_data_column
    .data_column_box {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 40px;
  }
  .action_calendar_right_tab
    .action_tab_content
    .filter_list_data_column
    .data_column_box
    .date-wrap {
    position: relative !important;
    width: auto;
    padding: 0 0 16px;
  }
  .action_calendar_right_tab .action_tab_content {
    padding-top: 0;
  }
  .action_calenda_filter_menu .filter_heading {
    margin-bottom: 25px;
  }
  .action_calendar_right_tab .list_view_img,
  .action_calendar_right_tab .list_view_img img {
    max-height: 300px;
    object-fit: contain;
  }
  .action_calendar_right_tab
    .action_tab_content
    .filter_list_data_column
    .data_column_box
    .lots_slider_cont_details {
    padding-top: 10px;
  }
  .action_calendar_right_tab
    .action_tab_content
    .filter_list_data_column
    .data_column_box
    .lots_slider_cont_details
    h4 {
    font-size: 16px;
    line-height: 26px;
    margin-top: 10px;
  }
  .action_calendar_right_tab
    .action_tab_content
    .filter_list_data_column
    .data_column_box
    .lots_slider_cont_details
    .border-list {
    margin-bottom: 20px !important;
  }
  .action_calendar_right_tab
    .action_tab_content
    .filter_list_data_column
    .data_column_box
    .lots_slider_cont_details
    .border-list
    li {
    font-size: 12px;
    line-height: 18px;
  }
  .action_calendar_right_tab
    .action_tab_content
    .filter_list_data_column
    .data_column_box
    .lots_slider_cont_details
    .border-list
    li:first-child {
    font-weight: 500;
  }
  .action_calendar_right_tab
    .action_tab_content
    .filter_list_data_column
    .data_column_box
    .lots_slider_cont_details
    .border-list
    li:nth-child(2)::before {
    display: block;
  }
  .action_calendar_right_tab
    .action_tab_content
    .filter_list_data_column
    .data_column_box
    .lots_slider_cont_details
    .browse_btn {
    font-size: 12px;
    line-height: 18px;
    width: 140px;
    padding: 9px 10px;
    border: 1px solid !important;
    color: #333333 !important;
    background: #ffffff;
  }
  .action_calendar_right_tab
    .action_tab_content
    .filter_list_data_column
    .data_column_box:last-child {
    padding-bottom: 0;
  }

  .custom_action_calendar_tabs .nav.nav-tabs {
    display: none;
  }
}
