.AboutUsContainer {
  min-height: 500px;
  /* margin: 100px 0px 0px 100px; */
}
.about-us-content-page {
  color: #fff;
  font-size: 80px;
  font-family: Noto Serif;
  font-weight: 700;
  text-align: center;
}

.about-us-content-inner-sec {
  width: 87%;
  margin: auto;
  margin-top: 50px;
}
.headerPreviewContent-aboutus {
  color: #000;
  font-size: 24px;
  font-family: Noto Serif;
  font-weight: 700;
  line-height: 44px;
}
.headersubContent-aboutus {
  color: #000;
  font-size: 18px;
  font-family: Noto Serif;
  line-height: 40px;
  margin-top: 24px;
}

@media (max-width: 600px) {
  .about-us-content-page {
    font-size: 50px;
  }
}
