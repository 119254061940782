.trendinglotsMainDiv {
  margin: 20px 30px;
}

.trendinglotImageMainDiv {
  width: 319px;
  padding: 0 15px;
}

.imageDiv {
  height: 300px;
  text-align: center;
  cursor: pointer;
}

.trendingLotImage {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.trendinglotImageTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 26px;
  font-family: "Noto Serif", serif;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 42px;
}

.trendingLotImageTextDiv span {
  font-size: 12px;
  color: rgba(17, 17, 17, 0.5);
}

.trendingLotImageTextDiv p {
  font-size: 12px;
  color: #111111;
}

.trendingLotFollowBtn {
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #111111 !important;
  border: 1px solid #111111 !important;
  border-radius: 4px !important;
  padding: 10px !important;
  transition: all 0.5s ease-in-out !important;
  background-color: #ffffff !important;
  box-shadow: unset !important;
}
.trendingLotFollowingBtn {
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #ffffff !important;
  border: 1px solid #111111 !important;
  border-radius: 4px !important;
  padding: 10px !important;
  transition: all 0.5s ease-in-out !important;
  background-color: #000000 !important;
  box-shadow: unset !important;
}

.bidSvg {
  margin-right: 6px;
}

.spinnerDiv {
  text-align: center;
}

.loadMoreBtn {
  width: 129px;
  height: 44px;
  background: #333333 !important;
  border-radius: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #ffffff !important;
  box-shadow: unset !important;
  margin: auto;
  margin-top: 10px !important;
}
