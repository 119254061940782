.browes_tab_content .mobile_right_sidebar_content .card {
  margin-bottom: 30px;
}

.submit_filter {
  background: #fff1e5;
  left: -16px;
  padding: 10px;
  position: relative;
  width: calc(100% + 30px);
}

.submit_filter a,
.filter_btn_submit_apply {
  outline: none;
  width: 171px;
  height: 38px;
  line-height: 38px;
  font-weight: 500;
  font-size: 12px;
  background: #000000;
  text-transform: uppercase;
}

.browes_tab_content .action_calenda_filter_menu .accordion {
  padding-bottom: 0;
}

.browes_tab_right_tab .browes_tab_content .filter_list .slider {
  width: 100%;
}

.rangeslider-horizontal {
  height: 1px;
}

.browes_tab_right_tab
  .browes_tab_content
  .filter_list
  .rangeslider-horizontal
  .rangeslider__handle {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 0.8px solid #111111;
  box-shadow: none;
  outline: 0;
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}

.browes_tab_right_tab .browes_tab_content .filter_list .value span {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
}
.mobile_right_sidebar_content .select_address_content .form-check {
  padding-left: 27px;
}

.mobile_right_sidebar_content
  .select_address_content
  .form-check
  .form-check-label::before {
  width: 18px;
  height: 18px;
  top: 4px;
  left: -25px;
  border: 1px solid #ddd;
}

.mobile_right_sidebar_content
  .select_address_content
  .form-check
  .form-check-label::after {
  width: 12px;
  height: 12px;
  top: 7px;
  left: -22px;
}

.mobile_right_sidebar_content .select_address_content .radioWrap {
  padding-bottom: 10px;
}

.mobile_right_sidebar_content
  .select_address_content
  .form-check
  .form-check-label {
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  font-size: 14px;
}

.card-body .slider-handles div[role="slider"] {
  width: 16px !important;
  height: 16px !important;
  background: #ffffff !important;
  border: 0.8px solid #111111;
  box-shadow: none !important;
  outline: 0;
}

.slider-tracks div {
  height: 1px !important;
  background: #000 !important;
}

.card-body .two_side_slider div:nth-child(2) {
  height: 1px !important;
}

.card-body .two_side_slider div div:nth-child(2) {
  height: 16px !important;
}

.card-body .two_side_slider {
  padding: 25px 0;
  width: calc(100% - 15px);
  margin: 0 auto;
}

.sortByFormCheckLabelText {
  font-style: normal;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  color: #19191d;
}
.sortByFormCheckLabelText .form-check-label {
  padding-bottom: 12px;
  padding-left: 10px;
}

.sortByFormCheckLabelText [type="radio"] {
  width: 18px;
  height: 18px;
}
.sortByFormCheckLabelText [type="radio"]:checked:after {
  background-color: #ffa500 !important;
}
.current-bid-filter-sec {
  display: flex;
  gap: 8px;
}
.bid-from-sec-center {
  width: 8px;
  padding-top: 28px;
}
.bid-from-sec-left,
.bid-from-sec-right {
  width: 48%;
}
.bid-from-sec-left-bottom input {
  width: 100%;
  border: none;
  color: #111;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: 23px;
  /* height: 33px; */
}
.bid-from-sec-left-bottom input:focus-visible {
  outline: none;
  border: none;
}
.reserve_met_row_item {
  display: flex;
  justify-content: space-between;
}
.bid-from-sec-left-top {
  color: #111;
  font-family: Noto Serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}
.bid-from-sec-left-bottom span {
  color: #111;
  text-align: right;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 184.615% */
}

.bid-from-sec-left-bottom > div {
  display: flex;
  gap: 8px;
  height: 33px;
  border-radius: 2px;
  border: 1px solid #111;
  padding: 5px 7px;
}
